import { CREATE, UPDATE, fetchUtils } from 'react-admin';
import drfProvider from 'ra-data-drf';
import { Subsidiary, getSubsidiary } from './subsidiaries';

// API config
export const API_URL = process.env.REACT_APP_API_BASE_URL;

const IMAGES_SOURCES = ['logo', 'picture'];

const dataContainsImageRawFile = (data: any) =>
  !!IMAGES_SOURCES.find(
    (imageSource: string) => data && data[imageSource] && data[imageSource].rawFile,
  );

const setRequestHeaders = (options: any) => {
  if (!options.headers) {
    options.headers = new Headers();
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  options.headers.set('Accept', 'application/json');

  return options;
};

const httpClient = (url: string, options: any = {}) => {
  return fetchUtils.fetchJson(url, setRequestHeaders(options));
};

const getFilteredData = (params: any) => {
  const { data } = params;
  IMAGES_SOURCES.forEach((imageSource: string) => {
    // If we have an image without rawFile, we should not send it for update or create
    if (data && data[imageSource] && !data[imageSource].rawFile) {
      delete data[imageSource];
    }
  });
  return data;
};

const emptyImageSources = (params: any) => ({
  ...params,
  data: Object.keys(params.data).reduce((data, formKey) => {
    if (IMAGES_SOURCES.includes(formKey)) {
      return data;
    }

    return { ...data, [formKey]: params.data[formKey] };
  }, {}),
});

const TYPE_TO_METHODE: { [key: string]: string } = {
  CREATE: 'POST',
  UPDATE: 'PUT',
};
/**
 * For posts update only with "logo" fields, convert uploaded image as file in multipart form
 */
const addUploadFeature = (requestHandler: any) => async (
  type: string,
  resource: string,
  params: any,
) => {
  if (type === CREATE || type === UPDATE) {
    params.data = getFilteredData(params);
    const { data } = params;
    // Notice that following condition can be true only when there is a `<ImageInput source=":source" />` component with :source in IMAGES_SOURCES
    if (dataContainsImageRawFile(data)) {
      // Send form images as FormData
      const form = new FormData();
      Object.keys(data).forEach((formKey: any) => {
        if (data[formKey] && data[formKey].rawFile) {
          form.append(formKey, data[formKey].rawFile);
        } else if (type === CREATE) {
          // We need a complete payload when creating (also it's simple so we can send all)
          form.append(formKey, data[formKey]);
        }
      });

      const { json } = await fetchUtils.fetchJson(
        `${API_URL}/api/${resource}/${type === UPDATE && data.id ? `${data.id}/` : ''}`,
        setRequestHeaders({
          method: TYPE_TO_METHODE[type],
          body: form,
        }),
      );

      // No need for another post if it's a creation, everything was sent
      if (type === CREATE) {
        return { data: { ...params.data, id: json.id } };
      }

      // We separate Payload and file when updating, because payload is too complicated
      return requestHandler(type, resource, emptyImageSources(params));
    }
  }

  // For other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

const coreRessources = [
  'schools',
  'language-skill-levels',
  'languages',
  'computer-skills',
  'companies',
  'activity-area',
];

const subsidiaryPrefix: { [key: string]: string } = {
  [Subsidiary.BUYING_PEERS]: 'buyingPeers',
  [Subsidiary.FINANCE_PEERS]: 'financePeers',
  [Subsidiary.SUPPLY_PEERS]: 'supplyPeers',
  [Subsidiary.SELLING_PEERS]: 'sellingPeers',
  [Subsidiary.QUALITY_PEERS]: 'qualityPeers',
};

const addUrlPrefix = (requestHandler: any) => async (
  type: string,
  resource: string,
  params: any,
) => {
  const subsidiaryName = getSubsidiary();
  if (coreRessources.includes(resource)) {
    return requestHandler(type, resource, params);
  } else {
    return requestHandler(type, `${subsidiaryPrefix[subsidiaryName]}/${resource}`, params);
  }
};

export const parseLongText = (input: string) =>
  input ? `<ul><li>${input.split('\n').join('</li>\n<li>')}</li></ul>` : '';

export const formatLongText = (input: string) =>
  input ? input.replace(/<ul>|<\/ul>|<li>|<\/li>/g, '') : '';

export default addUrlPrefix(addUploadFeature(drfProvider(`${API_URL}/api`, httpClient)));
