import React from 'react';
import {
  Datagrid,
  DeleteButton,
  FormTab,
  NumberField,
  ReferenceManyField,
  TextField,
  translate,
} from 'react-admin';
import { EditPortfolioButton, AddNewPortfolioButton, IsDirect } from 'pages/Portfolio/Components';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { resolveLocale } from 'services/translations';

const MAX_CATEGORIES = 8;

const styles = (theme: any) =>
  createStyles({
    addNewPortfolioButtonWrapper: {
      marginTop: 4 * theme.spacing.unit,
      marginBottom: 4 * theme.spacing.unit,
      display: 'flex',
      alignItems: 'center',
      width: 'auto',
    },
    addNewPortfolioButton: {
      marginRight: 2 * theme.spacing.unit,
    },
  });

const PortfolioTab = ({ classes, translate, index, isSmall, ...props }: any) => (
  <FormTab
    label={translate(`consultantskillset.tabs${isSmall ? '.small' : ''}.portfolios`, { index })}
    {...props}
  >
    <ReferenceManyField
      reference="procurement-portfolios-handled"
      target="consultant_skill_set"
      addLabel={false}
      sort={{ field: `procurement_category__is_direct`, order: 'DESC' }}
    >
      <Datagrid>
        <IsDirect
          source="procurement_category__is_direct"
          label={translate(
            'resources.procurement-portfolios-handled.fields.procurement_category.is_direct',
          )}
          sortable={false}
        />
        <TextField
          source={`procurement_category.name_${resolveLocale()}`}
          label={translate(
            'resources.procurement-portfolios-handled.fields.procurement_category.name',
          )}
          sortable={false}
        />
        <NumberField source="tactical_buy" sortable={false} />
        <NumberField source="project_purchasing" sortable={false} />
        <NumberField source="category_management" sortable={false} />
        <NumberField source="total_years_of_experience" sortable={false} />
        <EditPortfolioButton consultantskillsetId={props.record && props.record.id} />
        <DeleteButton redirect={false} />
      </Datagrid>
    </ReferenceManyField>

    <div className={classes.addNewPortfolioButtonWrapper}>
      <AddNewPortfolioButton
        className={classes.addNewPortfolioButton}
        consultantskillsetId={props.record && props.record.id}
        disabled={
          props.record &&
          props.record.procurement_portfolio_handled &&
          props.record.procurement_portfolio_handled.length >= MAX_CATEGORIES
        }
      />
      {props.record &&
        props.record.procurement_portfolio_handled &&
        props.record.procurement_portfolio_handled.length >= MAX_CATEGORIES && (
          <Typography>
            {translate('resources.procurement-portfolios-handled.max_error', {
              max_categories: MAX_CATEGORIES,
            })}
          </Typography>
        )}
    </div>
  </FormTab>
);

export default withStyles(styles)(translate(PortfolioTab));
