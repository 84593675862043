export enum Subsidiary {
  BUYING_PEERS = 'BuyingPeers',
  FINANCE_PEERS = 'FinancePeers',
  SUPPLY_PEERS = 'SupplyPeers',
  SELLING_PEERS = 'SellingPeers',
  QUALITY_PEERS = 'QualityPeers',
}

export const subsidiaryTranslateKeys: Record<Subsidiary, string> = {
  [Subsidiary.BUYING_PEERS]: 'resources.subsidiaries.buying_peers',
  [Subsidiary.FINANCE_PEERS]: 'resources.subsidiaries.finance_peers',
  [Subsidiary.SUPPLY_PEERS]: 'resources.subsidiaries.supply_peers',
  [Subsidiary.SELLING_PEERS]: 'resources.subsidiaries.selling_peers',
  [Subsidiary.QUALITY_PEERS]: 'resources.subsidiaries.quality_peers',
};

export const ORDERED_SUBSIDIARIES = [
  Subsidiary.BUYING_PEERS,
  Subsidiary.FINANCE_PEERS,
  Subsidiary.SUPPLY_PEERS,
  Subsidiary.SELLING_PEERS,
  Subsidiary.QUALITY_PEERS,
];

export const getSubsidiary = () => (localStorage.getItem('ownSubsidiary') as Subsidiary) || '';
