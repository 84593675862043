import { Experience } from '../types';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

export const formatExperiences = (experiences: Experience[]) => {
  const sortExperiences: any = experiences.sort((experience1: any, experience2: any) =>
    experience1.start_date > experience2.start_date ? 1 : -1,
  );

  return sortExperiences.map((experience: any) => ({
    ...experience,
    end_date: new Date(experience.end_date),
    start_date: new Date(experience.start_date),
    duration: Math.round(
      differenceInCalendarDays(new Date(experience.end_date), new Date(experience.start_date)) / 30,
    ),
  }));
};

export const groupFinancePeersExperiences = (experiences: Experience[], jobTitle: string) => {
  if (experiences.length === 0) {
    return [];
  }

  const accumulator = (total: Experience[], currentValue: Experience, currentIndex: number) => {
    if (currentIndex === 0) {
      return [currentValue];
    }

    const previousValue = total[total.length - 1];
    if (currentValue.as_internal_consultant && previousValue.as_internal_consultant) {
      // Increment the duration of the experience
      return [
        ...total.slice(0, -1),
        {
          ...previousValue,
          end_date: currentValue.end_date,
          duration: Math.round(
            differenceInCalendarDays(
              new Date(currentValue.end_date),
              new Date(previousValue.start_date),
            ) / 30,
          ),
          title: jobTitle,
        },
      ];
    }
    // Otherwise, create an other experience
    return [...total, currentValue];
  };
  return experiences.reduce(accumulator, []);
};

export const groupExperiences = (formatedExperiences: any) => {
  if (formatedExperiences.length === 0) {
    return [];
  }

  const accumulator = (total: any, currentValue: any, currentIndex: number) => {
    if (currentIndex === 0) {
      return [
        {
          end_date: currentValue.end_date,
          start_date: currentValue.start_date,
          nbOfCells: 1,
          as_internal_consultant: currentValue.as_internal_consultant,
        },
      ];
    }
    const previousValue = total[total.length - 1];
    if (currentValue.as_internal_consultant === previousValue.as_internal_consultant) {
      // Increment the duration of the group
      return [
        ...total.slice(0, -1),
        {
          ...previousValue,
          end_date: currentValue.end_date,
          nbOfCells: previousValue.nbOfCells + 1 + 1,
        },
      ];
    }
    // Otherwise, create an other group
    return [
      ...total,
      {
        end_date: currentValue.end_date,
        start_date: currentValue.start_date,
        nbOfCells: 1,
        as_internal_consultant: currentValue.as_internal_consultant,
      },
    ];
  };
  return formatedExperiences.reduce(accumulator, []);
};
