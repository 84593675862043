import React from 'react';
import { required, maxLength, translate } from 'react-admin';
import { TechnicalEnvironmentInput } from './ExperienceCustomInputs';
import { withStyles } from '@material-ui/core';
import { containerStyles } from 'components/customFormStyle';
import MultiLanguageTextInput from 'components/MultiLanguageTextInput';
import { addErrorsNumberOfLinesForMultilanguageInput } from 'services/errorHandler';

const MAX_NUMBER_OF_TECHNICAL_ENVIRONEMENT = 6;
const MAX_NUMBER_OF_REALISATIONS = 8;

export const validateQualityPeersExperience = (translate: any) => (values: any) => {
  const errors: any = {};
  if (
    values.technicalEnvironments &&
    values.technicalEnvironments.length > MAX_NUMBER_OF_TECHNICAL_ENVIRONEMENT
  ) {
    errors.technicalEnvironments = [
      translate('resources.experiences.fields.technical_environnement_max_error', {
        maxNumber: MAX_NUMBER_OF_TECHNICAL_ENVIRONEMENT,
      }),
    ];
  }

  addErrorsNumberOfLinesForMultilanguageInput({
    errors,
    values,
    source: 'realisations',
    maximum: MAX_NUMBER_OF_REALISATIONS,
    messageKey: 'resources.experiences.fields.realisations_max_error',
    translate,
  });
  return errors;
};

export const QualityPeersInputs = translate(
  withStyles(containerStyles)(({ translate, classes, ...props }: any) => (
    <div className={classes.container}>
      <MultiLanguageTextInput
        isLongTextInput
        source="mission"
        placeholderKey="resources.experiences.placeholder.QualityPeers.mission"
        validate={[required(), maxLength(255)]}
        rows="2"
        {...props}
      />

      <MultiLanguageTextInput
        source="stakes"
        placeholderKey="resources.experiences.placeholder.QualityPeers.stakes"
        validate={[required(), maxLength(255)]}
        {...props}
      />

      <TechnicalEnvironmentInput {...props} />
    </div>
  )),
);
