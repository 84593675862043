import React from 'react';
import { Edit, SaveButton, TabbedForm, Toolbar, translate, Responsive } from 'react-admin';
import { Button, createStyles, withStyles } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {
  AdditionalInformationsTab,
  ExperiencesTab,
  FormationsTab,
  PersonalInformationsTab,
  PortfolioTab,
  SummaryTab,
  QualityCompetenciesTab,
} from './Tabs';
import { Subsidiary, getSubsidiary } from 'services/subsidiaries';
import InterventionDomainsTab from './Tabs/InterventionDomainsTab';
import { Link } from 'react-router-dom';

const SUBSIDIARY_TO_TABS: { [key: string]: React.ElementType[] } = {
  [Subsidiary.BUYING_PEERS]: [
    PersonalInformationsTab,
    FormationsTab,
    ExperiencesTab,
    PortfolioTab,
    AdditionalInformationsTab,
    SummaryTab,
  ],
  [Subsidiary.SELLING_PEERS]: [
    PersonalInformationsTab,
    FormationsTab,
    ExperiencesTab,
    InterventionDomainsTab,
    AdditionalInformationsTab,
    SummaryTab,
  ],
  [Subsidiary.SUPPLY_PEERS]: [
    PersonalInformationsTab,
    FormationsTab,
    ExperiencesTab,
    InterventionDomainsTab,
    AdditionalInformationsTab,
    SummaryTab,
  ],
  [Subsidiary.FINANCE_PEERS]: [
    PersonalInformationsTab,
    FormationsTab,
    ExperiencesTab,
    InterventionDomainsTab,
    AdditionalInformationsTab,
    SummaryTab,
  ],
  [Subsidiary.QUALITY_PEERS]: [
    PersonalInformationsTab,
    FormationsTab,
    ExperiencesTab,
    QualityCompetenciesTab,
    AdditionalInformationsTab,
    SummaryTab,
  ],
};

const ConsultantSkillSetForm = translate(({ translate, ...props }: any) => {
  const subsidiary = getSubsidiary();
  const tabs = SUBSIDIARY_TO_TABS[subsidiary] || [];
  props.record.other_procurement_skills =
    props.record.other_procurement_skills || '<ul><li></li></ul>';
  props.record.language_skills = props.record.language_skills || '<ul><li></li></ul>';
  props.record.computer_skills = props.record.computer_skills || '<ul><li></li></ul>';
  props.record.hobbies = props.record.hobbies || '<ul><li></li></ul>';

  return (
    <Responsive
      medium={
        <TabbedForm {...props} toolbar={<CustomToolbar />} redirect={false}>
          {tabs.map((TabComponent: React.ElementType, index: number) => (
            <TabComponent index={index + 1} isSmall />
          ))}
        </TabbedForm>
      }
      large={
        <TabbedForm {...props} toolbar={<CustomToolbar />} redirect={false}>
          {tabs.map((TabComponent: React.ElementType, index: number) => (
            <TabComponent index={index + 1} />
          ))}
        </TabbedForm>
      }
    />
  );
});

const Title = ({ record }: any) => (
  <span>
    {record.user.first_name} {record.user.last_name}
  </span>
);

const styles = (theme: any) =>
  createStyles({
    icon: {
      marginRight: theme.spacing.unit,
    },
    saveButton: {
      marginRight: theme.spacing.unit,
    },
  });

// Remove the delete button and add preview button in the toolbar
const CustomToolbar = translate(
  withStyles(styles)((props: any) => (
    <Toolbar {...props}>
      <SaveButton className={props.classes.saveButton} />
      <Link
        style={{ textDecoration: 'none' }}
        to={`${props.basePath}/${props.record.id}/render`}
        target="_blank"
      >
        <Button color="default" variant="outlined">
          <VisibilityIcon className={props.classes.icon} />
          {props.translate('layout.preview')}
        </Button>
      </Link>
    </Toolbar>
  )),
);

const ConsultantSkillSetEdit = (props: any) => (
  <Edit {...props} title={<Title />} undoable={false}>
    <ConsultantSkillSetForm />
  </Edit>
);

export default ConsultantSkillSetEdit;
