import React from 'react';
import { Formation } from './types';
import BlockTitle from 'components/BlockTitle';
import { withStyles, createStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import { format } from 'date-fns';

const styles = createStyles({
  container: {
    height: '32mm',
  },
  list: {
    margin: '4mm 0 0 4mm',
  },
  listElement: {
    marginTop: '1mm',
  },
});

const SecondaryFormations = ({
  translate,
  classes,
  formations,
}: {
  translate: any;
  classes: any;
  formations: Formation[];
}) => {
  if (!formations) {
    return null;
  }
  const sortedFormation = formations
    .filter((formation: Formation) => !formation.is_main_formation)
    .filter(
      (formation: Formation) =>
        formation.formation_name && formation.school && formation.school.name,
    )
    .sort((formation1: Formation, formation2: Formation) =>
      formation1.end_date > formation2.end_date ? -1 : 1,
    )
    .slice(0, 4);

  if (!sortedFormation || sortedFormation.length === 0) {
    return null;
  }
  return (
    <div className={classes.container}>
      <BlockTitle title={translate('consultantskillset.pdf.secondary-formations.title')} />
      <ul className={classes.list}>
        {sortedFormation.map((formation: Formation) => (
          <li className={classes.listElement}>{`> ${formation.formation_name} - ${
            formation.school.name
          } - ${format(new Date(formation.end_date), 'yyyy')}`}</li>
        ))}
      </ul>
    </div>
  );
};

export default translate(withStyles(styles)(SecondaryFormations));
