import { GET_LIST, GET_MANY, GET_ONE } from 'react-admin';
import { format } from 'date-fns';
import { saveFormat } from 'services/date';
import { Subsidiary, getSubsidiary } from 'services/subsidiaries';
import { fillConsultantSkillSet } from './modelizerFunctions';
import { selectLabel } from 'services/translations';

// Maximum number of items to fetch in enums
const FETCH_LIMIT_ENUMS = 1000;
const GET_LIST_PARAMS = {
  pagination: { perPage: FETCH_LIMIT_ENUMS, page: 1 },
  sort: { field: 'name_fr', order: 'ASC' },
};

interface QueryConfig {
  resource: string;
  variableName: string;
  resourceType: any;
  requestParams: any;
}

const SUBSIDIARY_TO_SPECIFIC_DATAS: { [key in Subsidiary]?: QueryConfig[] } = {
  [Subsidiary.BUYING_PEERS]: [
    {
      resource: 'procurement_categories',
      variableName: 'procurementCategoriesData',
      resourceType: GET_LIST,
      requestParams: GET_LIST_PARAMS,
    },
    {
      resource: 'procurement_processes',
      variableName: 'procurementProcessesData',
      resourceType: GET_LIST,
      requestParams: GET_LIST_PARAMS,
    },
    {
      resource: 'procurement_levers',
      variableName: 'procurementLeversData',
      resourceType: GET_LIST,
      requestParams: GET_LIST_PARAMS,
    },
  ],
  [Subsidiary.SELLING_PEERS]: [],
  [Subsidiary.SUPPLY_PEERS]: [],
  [Subsidiary.FINANCE_PEERS]: [
    {
      resource: 'activity-area',
      variableName: 'activityAreasData',
      resourceType: GET_LIST,
      requestParams: GET_LIST_PARAMS,
    },
  ],
  [Subsidiary.QUALITY_PEERS]: [
    {
      resource: 'technical-environments',
      variableName: 'technicalEnvironmentsData',
      resourceType: GET_LIST,
      requestParams: GET_LIST_PARAMS,
    },
    {
      resource: 'competencies',
      variableName: 'competenciesData',
      resourceType: GET_LIST,
      requestParams: GET_LIST_PARAMS,
    },
    {
      resource: 'activity-area',
      variableName: 'activityAreasData',
      resourceType: GET_LIST,
      requestParams: GET_LIST_PARAMS,
    },
  ],
};

const getDatas = async (
  subsidiary: Subsidiary | '',
  consultantSkillSetId: string,
  dataProvider: any,
) => {
  if (!subsidiary) {
    return null;
  }

  const subsidirayQueryConfigs = SUBSIDIARY_TO_SPECIFIC_DATAS[subsidiary] || [];
  const [
    { data: computerSkillsData },
    { data: consultantSkillSet },
    ...otherDatas
  ] = await Promise.all([
    dataProvider(GET_LIST, 'computer-skills', GET_LIST_PARAMS),
    dataProvider(GET_ONE, 'consultantskillsets', { id: consultantSkillSetId }),
    ...subsidirayQueryConfigs.map(({ resource, resourceType, requestParams }) =>
      dataProvider(resourceType, resource, requestParams),
    ),
  ]);

  return {
    computerSkillsData,
    consultantSkillSet,
    ...subsidirayQueryConfigs.reduce(
      (datas, { variableName }, index) => ({ ...datas, [variableName]: otherDatas[index].data }),
      {},
    ),
  };
};

export const modelizeConsultantSkillSet = async (
  dataProvider: any,
  consultantSkillSetId: string,
) => {
  const subsidiary = getSubsidiary();

  const datas = await getDatas(subsidiary, consultantSkillSetId, dataProvider);

  if (datas === null) {
    return {};
  }

  // Get related resources data
  const { computerSkillsData, consultantSkillSet, ...otherDatas } = datas;

  // Fetch language skills
  consultantSkillSet.language_skills = await dataProvider(GET_MANY, 'language-skills', {
    ids: consultantSkillSet.language_skills,
  }).then((result: any) => result.data);

  // Replace related objects
  consultantSkillSet.computer_skills = consultantSkillSet.computer_skills.map((id: string) =>
    computerSkillsData.find((item: any) => item.id === id),
  );

  // TODO test to remove after adding experiences model to all subsidiaries
  consultantSkillSet.experiences = consultantSkillSet.experiences
    ? consultantSkillSet.experiences.map((experience: any) => {
        experience.end_date = experience.is_current
          ? format(new Date(), saveFormat)
          : experience.end_date;

        experience.title = selectLabel({
          name_fr: experience.title_fr,
          name_en: experience.title_en,
          name_de: experience.title_de,
        });
        experience.realisations = selectLabel({
          name_fr: experience.realisations_fr,
          name_en: experience.realisations_en,
          name_de: experience.realisations_de,
        });
        return experience;
      })
    : [];

  // Format summary
  consultantSkillSet.summary = selectLabel({
    name_fr: consultantSkillSet.summary_fr,
    name_en: consultantSkillSet.summary_en,
    name_de: consultantSkillSet.summary_de,
  });
  consultantSkillSet.job_title = selectLabel({
    name_fr: consultantSkillSet.job_title_fr,
    name_en: consultantSkillSet.job_title_en,
    name_de: consultantSkillSet.job_title_de,
  });
  consultantSkillSet.hobbies = selectLabel({
    name_fr: consultantSkillSet.hobbies_fr,
    name_en: consultantSkillSet.hobbies_en,
    name_de: consultantSkillSet.hobbies_de,
  });

  // Sort experiences by starting date decreasing
  consultantSkillSet.experiences.sort((exp1: any, exp2: any) =>
    exp1.start_date > exp2.start_date ? -1 : 1,
  );

  return fillConsultantSkillSet(consultantSkillSet, otherDatas, subsidiary);
};
