import React from 'react';
import { Create } from 'react-admin';
import { parse } from 'query-string';
import InterventionDomainForm from './InterventionDomainForm';
import MainSkillForm from './MainSkillForm';
import BackButton from 'components/BackButton';

export const InterventionDomainCreate = (props: any) => {
  const { consultantskillset } = parse(props.location.search);
  const redirect = `/consultantskillsets/${consultantskillset}/3`;

  return (
    <Create {...props} actions={<BackButton goTo={redirect} />}>
      <InterventionDomainForm redirect={redirect} location={props.location} />
    </Create>
  );
};

export const MainSkillCreate = (props: any) => {
  const { consultantskillset } = parse(props.location.search);
  const redirect = `/consultantskillsets/${consultantskillset}/3`;

  return (
    <Create {...props} actions={<BackButton goTo={redirect} />}>
      <MainSkillForm redirect={redirect} location={props.location} />
    </Create>
  );
};
