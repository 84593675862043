import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  Link,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
  translate,
  maxLength,
  Labeled,
} from 'react-admin';
import DatePicker from 'components/DatePicker';
import AddIcon from '@material-ui/icons/Add';
import { parse } from 'query-string';
import { apiDateFormatter, apiDateParser } from 'services/date';
import { createStyles, withStyles } from '@material-ui/core';
import { getSubsidiary } from 'services/subsidiaries';

const styles = createStyles({
  onSameLineContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flex: '1 1 auto',
    width: 'max-content',
  },
});

const AddNewSchoolButton = ({ consultantskillsetId, formationId }: any) => (
  <Button
    component={Link}
    to={{
      pathname: '/schools/create',
      search: `?consultantskillset=${consultantskillsetId}${
        formationId ? `&formationId=${formationId}` : ''
      }`,
    }}
    label="resources.formations.createschool"
  >
    <AddIcon />
  </Button>
);

// We need to separate the School Input into a separate component to pass down to the ReferenceInput
// all the props given by the Form parent component.
const SchoolInput = translate(
  withStyles(styles)(({ consultantskillset, formationId, classes, translate, ...props }: any) => (
    <div className={classes.onSameLineContainer}>
      <ReferenceInput
        source="school.id"
        label="resources.formations.fields.school.id"
        reference="schools"
        filterToQuery={(searchText: string) => ({ search: searchText })}
        sort={{ field: 'name', order: 'ASC' }}
        validate={required()}
      >
        <AutocompleteInput optionText={(choice: any) => choice.name} optionValue="id" />
      </ReferenceInput>
      <AddNewSchoolButton consultantskillsetId={consultantskillset} formationId={formationId} />
    </div>
  )),
);

const FormationForm = translate(({ redirect, location, translate, ...props }: any) => {
  const { consultantskillset, schoolId } = parse(location.search);
  if (consultantskillset) {
    props.record.consultantskillset = consultantskillset;
  }
  if (schoolId) {
    props.record.school = { id: schoolId };
  }

  const formationId = props.record.id;
  const subsidiary = getSubsidiary();

  return (
    <SimpleForm {...props} redirect={redirect}>
      <SchoolInput consultantskillset={consultantskillset} formationId={formationId} />

      <TextInput
        source="formation_name"
        placeholder={translate(`resources.formations.placeholder.${subsidiary}.formation_name`)}
        validate={[required(), maxLength(255)]}
      />

      <Labeled label={translate('resources.formations.fields.end_date')}>
        <DatePicker
          source="end_date"
          parse={apiDateParser}
          format={apiDateFormatter}
          validate={required()}
          views={['year']}
        />
      </Labeled>

      <BooleanInput source="is_main_formation" />
    </SimpleForm>
  );
});

export default FormationForm;
