import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { translate } from 'react-admin';
import { colorPalette } from 'stylesheet';
import { InterventionDomain } from './types';
import BlockTitle from 'components/BlockTitle';
import { selectLabel } from 'services/translations';
import { SUBSIDIARY_TO_LEVELS } from 'services/skillLevelAdministration';
import check from '../../assets/check-24px.svg';
import { getSubsidiary } from 'services/subsidiaries';

interface InterventionDomainProps {
  interventionDomains: InterventionDomain[];
  classes: any;
  translate: any;
}
const styles = ({ palette }: Theme) =>
  createStyles({
    tableContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      marginTop: '3mm',
    },
    table: {
      width: '100%',
      '& td': {
        verticalAlign: 'bottom',
      },
    },
    firstEmptyCol: {
      width: '2mm',
    },
    emptyCol: {
      width: '2mm',
    },
    lastEmptyCol: {
      width: '5mm',
    },
    headerUnderline: {
      borderBottom: `solid ${palette.primary.main} 1mm`,
      paddingBottom: '2mm',
      fontSize: '11pt',
    },
    secondHeader: {
      '& $headerUnderline': {
        paddingTop: '6mm',
      },
    },
    dataRow: {
      '& td': {
        padding: '4mm 0 2mm 0',
        whiteSpace: 'nowrap',
      },
    },
    dataUnderline: {
      borderBottom: `solid ${colorPalette.lightText} 0.5mm`,
    },
    bold: {
      fontWeight: 'bold',
      color: palette.primary.dark,
    },
    toCenter: {
      textAlign: 'center',
    },
    checkImageContainer: {
      position: 'relative',
    },
    checkImage: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    notEmptyCol: {
      width: '20%',
    },
  });

const Rows = ({ data, translate, classes, subsidiary }: any) =>
  data.map((interventionDomain: InterventionDomain) => (
    <tr className={classes.dataRow}>
      <td className={classes.firstEmptyCol} />
      <td className={`${classes.dataUnderline} ${classes.bold}`}>
        {selectLabel(interventionDomain.competency)}
      </td>
      <td className={`${classes.emptyCol} ${classes.dataUnderline}`} />
      {SUBSIDIARY_TO_LEVELS[subsidiary].map(({ id }: { id: string }, index: number) => (
        <td
          className={`${classes.toCenter} ${classes.dataUnderline} ${classes.checkImageContainer} ${
            index % 2 === 1 ? classes.emptyCol : classes.notEmptyCol // Even column is a large one, Odd ones are small gap
          }`}
        >
          {id === interventionDomain.level && (
            <img src={check} className={classes.checkImage} alt="" />
          )}
        </td>
      ))}
      <td className={`${classes.emptyCol} ${classes.dataUnderline}`} />
      <td className={`${classes.dataUnderline} ${classes.bold}`}>
        {parseFloat(interventionDomain.years_of_experience) > 0
          ? translate('consultantskillset.pdf.intervention-domains.years', {
              smart_count: parseFloat(interventionDomain.years_of_experience),
            })
          : null}
      </td>
      <td className={classes.lastEmptyCol}> </td>
    </tr>
  ));

const InterventionDomainsComponent = ({
  classes,
  interventionDomains,
  translate,
}: InterventionDomainProps) => {
  if (!interventionDomains) {
    return null;
  }

  const subsidiary = getSubsidiary();
  return (
    <>
      <BlockTitle title={translate('consultantskillset.pdf.intervention-domains.title')} />
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td colSpan={2} className={classes.headerUnderline}>
                {translate('consultantskillset.pdf.intervention-domains.competency')}
              </td>
              <td />
              {SUBSIDIARY_TO_LEVELS[subsidiary].map(({ translation_key, is_sub_column }) =>
                is_sub_column ? (
                  <td />
                ) : (
                  <td className={`${classes.toCenter} ${classes.headerUnderline}`}>
                    {translate(translation_key)}
                  </td>
                ),
              )}
              <td colSpan={2} className={classes.headerUnderline} />
            </tr>

            <Rows
              data={interventionDomains}
              classes={classes}
              translate={translate}
              subsidiary={subsidiary}
            />
          </tbody>
        </table>
      </div>
    </>
  );
};

export default translate(withStyles(styles)(InterventionDomainsComponent));
