import React from 'react';
import {
  Button,
  Link,
  EditButton,
  Datagrid,
  DeleteButton,
  ReferenceManyField,
  TextField,
  Labeled,
  translate,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import { withStyles, createStyles } from '@material-ui/core';
import { resolveLocale } from 'services/translations';

const MAX_LANGUAGES = 4;

const AddNewLanguageButton = ({ className, disabled, consultantskillsetId }: any) => (
  <Button
    className={className}
    component={Link}
    disabled={disabled}
    to={{
      pathname: '/language-skills/create',
      search: `?consultantskillset=${consultantskillsetId}`,
    }}
    label="resources.consultantskillsets.addlanguage"
  >
    <AddIcon />
  </Button>
);

const EditLanguageButton = ({ consultantskillsetId, ...props }: any) => {
  return (
    <EditButton
      component={Link}
      to={{
        pathname: `/language-skills/${props.record.id}`,
        search: `?consultantskillset=${consultantskillsetId}`,
      }}
    />
  );
};

const styles = (theme: any) =>
  createStyles({
    addNewLanguageButtonWrapper: {
      marginTop: 1 * theme.spacing.unit,
      marginBottom: 1 * theme.spacing.unit,
      display: 'flex',
      alignItems: 'center',
      width: 'auto',
    },
    addNewLanguageButton: {
      marginRight: 2 * theme.spacing.unit,
    },
    table: {
      width: 'auto',
    },
  });

export const LanguageSkillField = translate(
  withStyles(styles)(({ translate, classes, consultantskillsetId, ...props }: any) => (
    <>
      <Labeled label={translate('resources.consultantskillsets.fields.language_skills')}>
        <ReferenceManyField reference="language-skills" target="consultant_skill_set" {...props}>
          <Datagrid classes={classes}>
            <TextField
              source={`language.name_${resolveLocale()}`}
              label={translate('resources.consultantskillsets.fields.language')}
              sortable={false}
            />
            <TextField
              source={`level.name_${resolveLocale()}`}
              label={translate('resources.consultantskillsets.fields.languagelevel')}
              sortable={false}
            />
            <EditLanguageButton consultantskillsetId={consultantskillsetId} />
            <DeleteButton undoable={false} redirect={false} />
          </Datagrid>
        </ReferenceManyField>
      </Labeled>
      <div className={classes.addNewLanguageButtonWrapper}>
        <AddNewLanguageButton
          className={classes.addNewLanguageButton}
          consultantskillsetId={props.record.id}
          disabled={props.record.language_skills.length >= MAX_LANGUAGES}
        />
        {props.record.language_skills.length >= MAX_LANGUAGES && (
          <Typography>
            {translate('resources.language-skills.max_error', {
              max_languages: MAX_LANGUAGES,
            })}
          </Typography>
        )}
      </div>
    </>
  )),
);
