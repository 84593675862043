// This is a complete override of React Admin's AppBar
// There is a comment for every modification made from the original one
import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import { toggleSidebar as toggleSidebarAction } from 'ra-core';
// Use RA imports instead of relative ones
import { UserMenu, Headroom, translate } from 'react-admin';
// Our own imports
import { isBusinessManager } from 'services/permissions';
import Button from '@material-ui/core/Button';

const styles = (theme: any) =>
  createStyles({
    toolbar: {
      paddingRight: 24,
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'center',
    },
  });

const AppBar = ({
  children,
  classes,
  className,
  logo,
  logout,
  open,
  title,
  toggleSidebar,
  translate,
  userMenu,
  width,
  ...rest
}: any) => (
  <Headroom>
    <MuiAppBar className={className} color="secondary" position="static" {...rest}>
      <Toolbar
        disableGutters
        variant={width === 'xs' ? 'regular' : 'dense'}
        className={classes.toolbar}
      >
        {/* Hide drawer for consultants */}
        {isBusinessManager() && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSidebar}
            className={classes.menuButton}
          >
            <MenuIcon
              classes={{
                root: open ? classes.menuButtonIconOpen : classes.menuButtonIconClosed,
              }}
            />
          </IconButton>
        )}
        {/* Replace App Title, remove loading indicator, add feedback button */}
        <Typography variant="title" color="inherit" className={classes.title}>
          {translate('layout.app_title')}
        </Typography>
        <Button
          component="a"
          variant="contained"
          color="primary"
          href={process.env.REACT_APP_BUG_REPORT_FORM_URL}
          target="_blank"
          rel="noopener noreferer"
        >
          {translate('layout.feedback_button')}
        </Button>
        {cloneElement(userMenu, { logout })}
      </Toolbar>
    </MuiAppBar>
  </Headroom>
);

AppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string,
};

AppBar.defaultProps = {
  userMenu: <UserMenu />,
};

const enhance = compose(
  connect(
    (state: any) => ({
      locale: state.i18n.locale, // force redraw on locale change
    }),
    {
      toggleSidebar: toggleSidebarAction,
    },
  ),
  withStyles(styles),
  withWidth(),
  translate, // Add translate
);

export default enhance(AppBar);
