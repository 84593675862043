import React from 'react';
import { Edit } from 'react-admin';
import { parse } from 'query-string';
import LanguageSkillForm from './LanguageSkillForm';
import BackButton from 'components/BackButton';

const LanguageSkillEdit = (props: any) => {
  const { consultantskillset } = parse(props.location.search);
  const redirect = `/consultantskillsets/${consultantskillset}/4`;

  return (
    <Edit {...props} actions={<BackButton goTo={redirect} />} undoable={false}>
      <LanguageSkillForm redirect={redirect} location={props.location} />
    </Edit>
  );
};

export default LanguageSkillEdit;
