import React from 'react';
import { FormTab, LongTextInput, translate, minLength, maxLength } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/core';
import { Subsidiary, getSubsidiary } from 'services/subsidiaries';

const styles = (theme: any) =>
  createStyles({
    onSameLineContainer: {
      display: 'flex',
      alignItems: 'baseline',
      flexGrow: 1,
      flexDirection: 'column',
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
      },
    },
    cells: {
      width: '100%',
      marginBottom: 2 * theme.spacing.unit,
      [theme.breakpoints.up('lg')]: {
        width: '50%',
      },
    },
    rightCell: {
      [theme.breakpoints.up('lg')]: {
        paddingLeft: '2rem',
      },
    },
    instruction: {
      width: '100%',
      marginTop: 2 * theme.spacing.unit,
    },
  });

const TextAndExample = translate(
  withStyles(styles)(({ language, subsidiaryName, translate, classes, ...props }: any) => (
    <div className={classes.onSameLineContainer}>
      <div className={classes.cells}>
        <LongTextInput
          {...props}
          source={`summary_${language}`}
          rows="10"
          style={{ width: '100%' }}
        />
      </div>
      <Typography className={`${classes.cells} ${classes.rightCell}`}>
        {translate(`resources.consultantskillsets.${subsidiaryName}.summary_example_${language}`)}
      </Typography>
    </div>
  )),
);

const VALIDATE_TEXT: { [key: string]: any[] } = {
  [Subsidiary.BUYING_PEERS]: [],
  [Subsidiary.SELLING_PEERS]: [minLength(240), maxLength(390)],
  [Subsidiary.SUPPLY_PEERS]: [minLength(240), maxLength(390)],
  [Subsidiary.FINANCE_PEERS]: [],
  [Subsidiary.QUALITY_PEERS]: [],
};
const SummaryTab = translate(
  withStyles(styles)(({ classes, translate, index, isSmall, ...props }: any) => {
    const subsidiaryName = getSubsidiary();

    const validateText = VALIDATE_TEXT[subsidiaryName];

    return (
      <FormTab
        label={translate(`consultantskillset.tabs${isSmall ? '.small' : ''}.summary`, { index })}
        {...props}
      >
        <Typography variant="subheading" className={classes.instruction}>
          {translate(`resources.consultantskillsets.summaryinstructions.${subsidiaryName}`)}
        </Typography>
        <TextAndExample language="en" validate={validateText} subsidiaryName={subsidiaryName} />
        <TextAndExample language="fr" validate={validateText} subsidiaryName={subsidiaryName} />
        <TextAndExample language="de" validate={validateText} subsidiaryName={subsidiaryName} />
      </FormTab>
    );
  }),
);

export default SummaryTab;
