import React, { Fragment } from 'react';
import { withStyles, createStyles } from '@material-ui/core';

const styles = createStyles({
  line: {
    height: 0,
  },
  gap: {
    width: '2mm',
  },
});

const TableHeader = ({ classes, formatedExperiences }: any) => (
  <tr className={classes.line}>
    {formatedExperiences.map((group: any, index: number) => (
      <Fragment key={index}>
        <td className={classes.gap} />
        <td />
      </Fragment>
    ))}
    {formatedExperiences.length > 0 ? <td className={classes.gap} /> : null}
  </tr>
);

export default withStyles(styles)(TableHeader);
