import React from 'react';
import { connect } from 'react-redux';
import { CardActions, translate } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { SET_LANGUAGE } from 'redux/exportLanguageReducer';

const LANGUAGES: { [key in string]: string } = {
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
};

const styles = createStyles({
  label: {
    margin: 'auto 0.5rem',
  },
  container: {
    display: 'flex',
  },
});

const SelectLanguage = ({ language, setLanguage, translate, classes }: any) => {
  const handleChange = (event: any) => {
    setLanguage(event.target.value);
  };

  return (
    <CardActions className={classes.conatiner}>
      <Typography className={classes.label}>
        {translate('consultantskillset.header.exportlanguage')}
      </Typography>
      <Select value={language} onChange={handleChange} displayEmpty>
        {Object.keys(LANGUAGES).map((key: string) => (
          <MenuItem value={key} key={key}>
            {LANGUAGES[key]}
          </MenuItem>
        ))}
      </Select>
    </CardActions>
  );
};
const mapStateToProps = (state: any) => ({ language: state.exportLanguage });
const mapDispatchToProps = (dispatch: any) => ({
  setLanguage: (language: string) => dispatch({ type: SET_LANGUAGE, payload: { language } }),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(translate(SelectLanguage)),
);
