import React from 'react';
import { Create } from 'react-admin';
import { parse } from 'query-string';
import ExperienceForm from './ExperienceForm';
import BackButton from 'components/BackButton';

const ExperienceCreate = (props: any) => {
  // Read the consultantskillset_id from the location which is injected by React Router
  // and passed to our component by react-admin automatically
  const { consultantskillset } = parse(props.location.search);
  const redirect = `/consultantskillsets/${consultantskillset}/2`;

  return (
    <Create {...props} actions={<BackButton goTo={redirect} />}>
      <ExperienceForm redirect={redirect} location={props.location} />
    </Create>
  );
};

export default ExperienceCreate;
