import React from 'react';
import {
  AutocompleteInput,
  FormTab,
  NumberInput,
  TextField,
  translate,
  minValue,
  ImageInput,
  BooleanInput,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SeniorityForm from './SeniorityForm';
import JobTitleForm from './JobTitleForm';
import { Subsidiary, getSubsidiary } from 'services/subsidiaries';
import { createStyles, withStyles } from '@material-ui/core';

const SUBSIDIARY_FORMS: { [key: string]: Function } = {
  [Subsidiary.BUYING_PEERS]: SeniorityForm,
  [Subsidiary.SELLING_PEERS]: JobTitleForm,
  [Subsidiary.SUPPLY_PEERS]: SeniorityForm,
  [Subsidiary.FINANCE_PEERS]: JobTitleForm,
  [Subsidiary.QUALITY_PEERS]: SeniorityForm,
};

const genderChoices = [
  {
    id: 'Male',
    name: 'consultantskillset.pdf.header.gender.male',
    explanation: 'resources.consultantskillsets.male',
  },
  {
    id: 'Female',
    name: 'consultantskillset.pdf.header.gender.female',
    explanation: 'resources.consultantskillsets.female',
  },
];

const imageStyle = createStyles({
  image: {
    width: '7em',
    height: '7em',
    borderRadius: '50%',
    filter: 'grayscale(1)',
  },
});
const optionRenderer = (choice: any) => choice.explanation;

const CustomImageField = withStyles(imageStyle)(
  ({ record, source, title, classes, ...props }: any) =>
    record[source] || record ? (
      <img src={record[source] || record} alt={title} className={classes.image} />
    ) : null,
);

const PersonalInformationsTab = ({ translate, index, isSmall, ...props }: any) => {
  const subsidiaryName = getSubsidiary();
  const SubsidiaryForm = SUBSIDIARY_FORMS[subsidiaryName];
  return (
    <FormTab
      label={translate(`consultantskillset.tabs${isSmall ? '.small' : ''}.personal_informations`, {
        index,
      })}
      {...props}
    >
      <TextField source="user.first_name" />
      <TextField source="user.last_name" />
      <AutocompleteInput source="gender" choices={genderChoices} optionText={optionRenderer} />
      <TextField source="user.email" />
      <NumberInput
        source="years_of_experience"
        validate={[minValue(0, translate('resources.consultantskillsets.negative_error'))]}
      />
      <SubsidiaryForm />
      <ImageInput
        source="picture"
        accept="image/*"
        placeholder={
          <Typography>{translate('resources.consultantskillsets.placeholder.picture')}</Typography>
        }
      >
        <CustomImageField
          source="src"
          title={translate('resources.consultantskillsets.fields.picture')}
        />
      </ImageInput>
      <BooleanInput
        source="display_picture"
        label={translate('resources.consultantskillsets.fields.display_picture')}
      />
    </FormTab>
  );
};

export default translate(PersonalInformationsTab);
