import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  Button,
  Link,
  ReferenceInput,
  required,
  FormDataConsumer,
  translate,
  ReferenceArrayInput,
  SelectArrayInput,
  Labeled,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { resolveLocale, selectLabel } from 'services/translations';
import AddIcon from '@material-ui/icons/Add';
import { apiDateFormatter, apiDateParser } from 'services/date';
import { createStyles, withStyles } from '@material-ui/core';

import DatePicker from 'components/DatePicker';

const styles = (theme: any) =>
  createStyles({
    onSameLineContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      flex: '1 1 auto',
      width: 'max-content',
    },
    leftComponent: {
      marginRight: 2 * theme.spacing.unit,
      width: '100%',
    },
    endDateToggle: {
      margin: `auto 0 0 ${2 * theme.spacing.unit}px`,
    },
  });

const AddNewCompanyButton = ({ consultantskillsetId, experienceId }: any) => (
  <Button
    component={Link}
    to={{
      pathname: '/companies/create',
      search: `?consultantskillset=${consultantskillsetId}${
        experienceId ? `&experienceId=${experienceId}` : ''
      }`,
    }}
    label="resources.experiences.createCompany"
  >
    <AddIcon />
  </Button>
);

// We need to separate the Company Input into a separate component to pass down to the ReferenceInput
// all the props given by the Form parent component.
export const CompanyInput = translate(
  withStyles(styles)(({ consultantskillset, experienceId, classes, translate, ...props }: any) => (
    <div className={classes.onSameLineContainer}>
      <ReferenceInput
        {...props}
        source="company.id"
        reference="companies"
        filterToQuery={(searchText: string) => ({ search: searchText })}
        label={translate('resources.experiences.fields.company.id')}
        validate={required()}
        className={`${props.className} ${classes.leftComponent}`}
      >
        <AutocompleteInput optionText={(choice: any) => choice.name} optionValue="id" />
      </ReferenceInput>
      <AddNewCompanyButton consultantskillsetId={consultantskillset} experienceId={experienceId} />
    </div>
  )),
);

export const EndDateInput = translate(
  withStyles(styles)(({ classes, translate, ...props }: any) => (
    <div className={classes.onSameLineContainer}>
      <FormDataConsumer {...props}>
        {({ formData }: any) => (
          <Labeled label={translate('resources.experiences.fields.end_date')}>
            <DatePicker
              source="end_date"
              parse={apiDateParser}
              format={apiDateFormatter}
              className={`${props.className} ${classes.leftComponent}`}
              views={['month', 'year']}
              disabled={formData.is_current}
            />
          </Labeled>
        )}
      </FormDataConsumer>

      <BooleanInput
        {...props}
        source="is_current"
        label="resources.experiences.fields.is_current"
        className={`${props.className} ${classes.endDateToggle}`}
      />
    </div>
  )),
);

export const PerimeterInput = translate(
  withStyles(styles)(({ consultantskillset, experienceId, classes, translate, ...props }: any) => (
    <div className={classes.onSameLineContainer}>
      <ReferenceInput
        {...props}
        source="perimeter.id"
        reference="perimeters"
        label={translate('resources.experiences.fields.perimeter.id')}
        className={`${props.className} ${classes.leftComponent}`}
      >
        <AutocompleteInput optionText={selectLabel} />
      </ReferenceInput>
      <Typography>{translate('resources.experiences.mandatoryForPurchases')}</Typography>
    </div>
  )),
);

export const ProcurementProcessesInput = translate(
  withStyles(styles)(({ consultantskillset, experienceId, classes, translate, ...props }: any) => (
    <div className={classes.onSameLineContainer}>
      <ReferenceArrayInput
        {...props}
        source="procurement_processes"
        reference="procurement_processes"
        label={translate('resources.experiences.fields.procurement_processes')}
        sort={{ field: `name_${resolveLocale()}`, order: 'ASC' }}
        className={`${props.className} ${classes.leftComponent}`}
      >
        <SelectArrayInput optionText={selectLabel} />
      </ReferenceArrayInput>
      <Typography>{translate('resources.experiences.mandatoryForPurchases')}</Typography>
    </div>
  )),
);

export const ProcurementCategoriesInput = translate(
  withStyles(styles)(({ consultantskillset, experienceId, classes, translate, ...props }: any) => (
    <div className={classes.onSameLineContainer}>
      <ReferenceArrayInput
        {...props}
        source="procurement_categories"
        reference="procurement_categories"
        label={translate('resources.experiences.fields.procurement_categories')}
        sort={{ field: `name_${resolveLocale()}`, order: 'ASC' }}
        className={`${props.className} ${classes.leftComponent}`}
      >
        <SelectArrayInput optionText={selectLabel} />
      </ReferenceArrayInput>
      <Typography>{translate('resources.experiences.mandatoryForPurchases')}</Typography>
    </div>
  )),
);

export const ProcurementLeversInput = translate(
  withStyles(styles)(({ consultantskillset, experienceId, classes, translate, ...props }: any) => (
    <div className={classes.onSameLineContainer}>
      <ReferenceArrayInput
        {...props}
        source="procurement_levers"
        reference="procurement_levers"
        label={translate('resources.experiences.fields.procurement_levers')}
        sort={{ field: `name_${resolveLocale()}`, order: 'ASC' }}
        className={`${props.className} ${classes.leftComponent}`}
      >
        <SelectArrayInput optionText={selectLabel} />
      </ReferenceArrayInput>
      <Typography>{translate('resources.experiences.mandatoryForPurchases')}</Typography>
    </div>
  )),
);

export const TechnicalEnvironmentInput = translate(
  withStyles(styles)(({ consultantskillset, experienceId, classes, translate, ...props }: any) => (
    <div className={classes.onSameLineContainer}>
      <ReferenceArrayInput
        {...props}
        source="technicalEnvironments"
        reference="technical-environments"
        label={translate('resources.experiences.fields.technicalEnvironments')}
        sort={{ field: `name_${resolveLocale()}`, order: 'ASC' }}
        className={`${props.className} ${classes.leftComponent}`}
      >
        <SelectArrayInput optionText={selectLabel} />
      </ReferenceArrayInput>
      <Typography>{translate('resources.experiences.mandatoryForQuality')}</Typography>
    </div>
  )),
);
