import React from 'react';
import {
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SelectArrayInput,
  SimpleFormIterator,
  ArrayInput,
  FormTab,
  translate,
  NumberInput,
  maxLength,
} from 'react-admin';
import { selectLabel } from 'services/translations';
import { required } from 'ra-core';
import { Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';

const MAX_COMPETENCIES = 6;

const styles = createStyles({
  reminder: {
    marginTop: '2rem',
  },
});

const FormationsTab = ({ translate, classes, index, isSmall, ...props }: any) => (
  <FormTab
    label={translate(`consultantskillset.tabs${isSmall ? '.small' : ''}.competency_levels`, {
      index,
    })}
    {...props}
  >
    <Typography className={classes.reminder}>
      {translate('resources.competency_levels.reminder')}
    </Typography>
    <ArrayInput
      source="competency_levels"
      reference="competency_levels"
      validate={maxLength(
        MAX_COMPETENCIES,
        translate('resources.competency_levels.max_error', { max_competencies: MAX_COMPETENCIES }),
      )}
      label="" //set label="" to hide the title of the liste
      fullWidth
    >
      <SimpleFormIterator>
        <ReferenceInput
          source="competency"
          reference="competencies"
          label={translate('resources.competency_levels.fields.competency')}
          validate={required()}
        >
          <SelectInput optionText={selectLabel} />
        </ReferenceInput>
        <ReferenceArrayInput
          source="activity_areas"
          reference="activity-area"
          label={translate('resources.competency_levels.fields.activity_areas')}
          validate={required()}
        >
          <SelectArrayInput optionText={selectLabel} />
        </ReferenceArrayInput>

        <NumberInput
          source="years_of_experience"
          label={translate('resources.competency_levels.fields.years_of_experience')}
          validate={required()}
        />
      </SimpleFormIterator>
    </ArrayInput>
  </FormTab>
);

export default translate(withStyles(styles)(FormationsTab));
