import React, { Fragment } from 'react';
import { formatDate } from 'services/date';
import { withStyles, createStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import { colorPalette } from 'stylesheet';
import { YEAR_PER_EXPERIENCE, YEAR_PER_GROUP } from 'pages/ConsultantSkillSetPdf/Timeline';

const styles = createStyles({
  experienceCell: {
    width: '100%',
    textAlign: 'center',
  },
  date: {
    position: 'relative',
    left: '-9mm',
    width: '20mm',
    textAlign: 'center',
    color: colorPalette.darkText,
  },
});

const YearLine = ({ classes, dateDisplay, dateBlock }: any) => (
  <tr>
    {dateBlock.map((group: any) => (
      <Fragment key={group.start_date.toString()}>
        <td className={classes.experienceCell}>
          <div className={classes.date}>{formatDate(group.start_date, 'yyyy')}</div>
        </td>
        {dateDisplay === YEAR_PER_GROUP && (
          <td colSpan={group.nbOfCells} className={classes.experienceCell} />
        )}
        {dateDisplay === YEAR_PER_EXPERIENCE && (
          <td colSpan={1} className={classes.experienceCell} />
        )}
      </Fragment>
    ))}
    {dateBlock.length > 0 ? (
      <td className={classes.experienceCell}>
        <div className={classes.date}>
          {formatDate(dateBlock[dateBlock.length - 1].end_date, 'yyyy')}
        </div>
      </td>
    ) : null}
  </tr>
);

export default translate(withStyles(styles)(YearLine));
