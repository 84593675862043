import { InterventionDomainEdit, MainSkillEdit } from './Edit';
import { InterventionDomainCreate, MainSkillCreate } from './Create';

export const InterventionDomain = {
  name: 'intervention-domains',
  edit: InterventionDomainEdit,
  create: InterventionDomainCreate,
};

export const MainSkill = {
  name: 'main-skills',
  edit: MainSkillEdit,
  create: MainSkillCreate,
};
