import React from 'react';
import { translate, Create, SimpleForm, TextInput } from 'react-admin';
import { parse } from 'query-string';
import BackButton from 'components/BackButton';

const SchoolCreate = (props: any) => {
  const { consultantskillset, formationId } = parse(props.location.search);

  const redirect = (_: any, id: string) => {
    return `/formations/${
      formationId ? formationId : 'create'
    }?consultantskillset=${consultantskillset}&schoolId=${id}`;
  };
  const redirectBack = `/formations/${
    formationId ? formationId : 'create'
  }?consultantskillset=${consultantskillset}`;

  return (
    <Create {...props} actions={<BackButton goTo={redirectBack} />}>
      <SimpleForm redirect={redirect}>
        <TextInput source="name" parse={(name: string) => name.toUpperCase()} />
      </SimpleForm>
    </Create>
  );
};

export default translate(SchoolCreate);
