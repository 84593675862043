import React from 'react';
import { Button, Link, EditButton, translate } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';

export const AddNewPortfolioButton = ({ className, disabled, consultantskillsetId }: any) => (
  <Button
    className={className}
    component={Link}
    disabled={disabled}
    to={{
      pathname: '/procurement-portfolios-handled/create',
      search: `?consultantskillset=${consultantskillsetId}`,
    }}
    label="resources.procurement-portfolios-handled.add"
  >
    <AddIcon />
  </Button>
);

export const EditPortfolioButton = ({ consultantskillsetId, ...props }: any) => {
  return (
    <EditButton
      component={Link}
      to={{
        pathname: `/procurement-portfolios-handled/${props.record.id}`,
        search: `?consultantskillset=${consultantskillsetId}`,
      }}
    />
  );
};

export const IsDirect = translate(({ record, translate }: any) => {
  if (!record) return null;

  if (record['procurement_category']['is_direct']) {
    return <div>{translate('resources.procurement-portfolios-handled.direct')}</div>;
  }
  return <div>{translate('resources.procurement-portfolios-handled.indirect')}</div>;
});
