import React from 'react';
import { FullPageForm } from 'components/CustomLogin';
import RequestPasswordForm from './RequestPasswordForm';

const RequestPassword = (props: any) => (
  <FullPageForm {...props}>
    <RequestPasswordForm params={props.match && props.match.params} />
  </FullPageForm>
);

export default RequestPassword;
