import React from 'react';
import { Create } from 'react-admin';
import { parse } from 'query-string';
import PortfolioForm from './PortfolioForm';
import BackButton from 'components/BackButton';

const PortfolioCreate = (props: any) => {
  const { consultantskillset } = parse(props.location.search);
  const redirect = `/consultantskillsets/${consultantskillset}/3`;

  return (
    <Create {...props} actions={<BackButton goTo={redirect} />}>
      <PortfolioForm redirect={redirect} location={props.location} />
    </Create>
  );
};

export default PortfolioCreate;
