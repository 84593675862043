import React from 'react';
import { ReferenceManyField, FormTab, translate } from 'react-admin';
import FormationCards from 'pages/Formation/FormationCards';

const FormationsTab = ({ translate, index, isSmall, ...props }: any) => (
  <FormTab
    label={translate(`consultantskillset.tabs${isSmall ? '.small' : ''}.formations`, { index })}
    {...props}
  >
    <ReferenceManyField reference="formations" target="consultant_skill_set" addLabel={false}>
      <FormationCards consultantskillsetId={props.record && props.record.id} />
    </ReferenceManyField>
  </FormTab>
);

export default translate(FormationsTab);
