import React from 'react';
import { Edit } from 'react-admin';
import { parse } from 'query-string';
import BackButton from 'components/BackButton';
import FormationForm from './FormationForm';

const FormationEdit = (props: any) => {
  const { consultantskillset } = parse(props.location.search);
  const redirect = `/consultantskillsets/${consultantskillset}/1`;

  return (
    <Edit {...props} actions={<BackButton goTo={redirect} />} undoable={false}>
      <FormationForm redirect={redirect} location={props.location} />
    </Edit>
  );
};

export default FormationEdit;
