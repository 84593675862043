import React from 'react';

import { translate, maxLength } from 'react-admin';
import MultiLanguageTextInput from 'components/MultiLanguageTextInput';
import { getSubsidiary } from 'services/subsidiaries';

const JobTitleForm = ({ translate, ...props }: any) => {
  const subsidiaryName = getSubsidiary();

  return (
    <MultiLanguageTextInput
      {...props}
      placeholderKey={`resources.consultantskillsets.placeholder.${subsidiaryName}.job_title`}
      source="job_title"
      validate={maxLength(255)}
    />
  );
};

export default translate(JobTitleForm);
