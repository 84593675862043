import React, { Fragment } from 'react';
import { Experience } from '../types';
import { withStyles, createStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import { colorPalette } from 'stylesheet';
import BlockTitle from 'components/BlockTitle';
import { formatExperiences, groupExperiences, groupFinancePeersExperiences } from './utils';
import CompanyLine from './Components/CompanyLine';
import { Subsidiary, getSubsidiary } from 'services/subsidiaries';
import YearLine from './Components/YearLine';
import ActivityAreaLine from './Components/ActivityAreaLine';
import TableHeader from './Components/TableHeader';
import PeersGroupLogoLine from './Components/PeersGroupLogoLine';

const styles = createStyles({
  tableContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2mm',
  },
  table: {
    tableLayout: 'fixed',
    width: '100%',
  },
  experienceCell: {
    width: '100%',
    textAlign: 'center',
  },
  experienceText: {
    color: colorPalette.darkText,
    paddingTop: '2mm',
  },
});

interface TimelineProps {
  experiences: Experience[];
  jobTitle: string;
  classes: any;
  translate: any;
}

export const YEAR_PER_EXPERIENCE = 'PER_EXPERIENCE';
export const YEAR_PER_GROUP = 'PER_GROUP';

const DATE_DISPLAY: { [key: string]: string } = {
  [Subsidiary.BUYING_PEERS]: YEAR_PER_GROUP,
  [Subsidiary.SELLING_PEERS]: YEAR_PER_EXPERIENCE,
  [Subsidiary.SUPPLY_PEERS]: YEAR_PER_GROUP,
  [Subsidiary.FINANCE_PEERS]: YEAR_PER_GROUP,
  [Subsidiary.QUALITY_PEERS]: YEAR_PER_GROUP,
};
const SHOW_ACTIVITY_AREA: { [key: string]: boolean } = {
  [Subsidiary.BUYING_PEERS]: false,
  [Subsidiary.SELLING_PEERS]: true,
  [Subsidiary.SUPPLY_PEERS]: true,
  [Subsidiary.FINANCE_PEERS]: false,
  [Subsidiary.QUALITY_PEERS]: false,
};

const SHOW_CLIENT: { [key: string]: boolean } = {
  [Subsidiary.BUYING_PEERS]: true,
  [Subsidiary.SELLING_PEERS]: true,
  [Subsidiary.SUPPLY_PEERS]: true,
  [Subsidiary.FINANCE_PEERS]: false,
  [Subsidiary.QUALITY_PEERS]: true,
};

const showClient = (subsidiary: string, experience: Experience) =>
  SHOW_CLIENT[subsidiary] || !experience.as_internal_consultant;

const displayDuration = (duration: number, translate: any) => {
  const nbMonths = duration % 12;
  const nbYears = Math.floor(duration / 12);
  return `${
    nbYears > 0
      ? translate('consultantskillset.pdf.timeline.years', {
          smart_count: nbYears,
        })
      : ''
  } ${
    nbMonths > 0
      ? translate('consultantskillset.pdf.timeline.months', {
          smart_count: nbMonths,
        })
      : ''
  }`;
};
const Timeline = ({ classes, experiences, jobTitle, translate }: TimelineProps) => {
  const subsidiary = getSubsidiary();
  let formatedExperiences = formatExperiences(experiences);
  if (subsidiary === Subsidiary.FINANCE_PEERS) {
    formatedExperiences = groupFinancePeersExperiences(formatedExperiences, jobTitle);
  }
  const groupedExperiences = groupExperiences(formatedExperiences);

  return (
    <>
      <BlockTitle title={translate('consultantskillset.pdf.timeline.title')} />
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>
            <TableHeader formatedExperiences={formatedExperiences} />
            <PeersGroupLogoLine
              groupedExperiences={groupedExperiences}
              showLogos={SHOW_CLIENT[subsidiary]}
            />
            <YearLine
              dateBlock={
                DATE_DISPLAY[subsidiary] === YEAR_PER_EXPERIENCE
                  ? formatedExperiences
                  : groupedExperiences
              }
              dateDisplay={DATE_DISPLAY[subsidiary]}
            />
            <CompanyLine
              formatedExperiences={formatedExperiences}
              subsidiary={subsidiary}
              showClient={showClient}
            />
            <tr>
              {formatedExperiences.map((experience: any) => (
                <Fragment key={experience.title}>
                  <td />
                  <td colSpan={1}>
                    <div className={`${classes.experienceCell} ${classes.experienceText}`}>
                      {experience.title}
                    </div>
                  </td>
                </Fragment>
              ))}
              <td />
            </tr>
            {SHOW_ACTIVITY_AREA[subsidiary] &&
              formatedExperiences.find((experience: any) => !!experience.activityArea) && (
                <ActivityAreaLine classes={classes} formatedExperiences={formatedExperiences} />
              )}
            <tr>
              {formatedExperiences.map((experience: any) => (
                <Fragment key={experience.title}>
                  <td />
                  <td colSpan={1}>
                    <div className={`${classes.experienceCell} ${classes.experienceText}`}>
                      {displayDuration(experience.duration, translate)}
                    </div>
                  </td>
                </Fragment>
              ))}
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default translate(withStyles(styles)(Timeline));
