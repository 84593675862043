import React, { useCallback } from 'react';
import { FormTab, ReferenceArrayInput, SelectArrayInput, translate } from 'react-admin';
import { LanguageSkillField } from 'pages/LanguageSkill/Components';
import { resolveLocale, selectLabel } from 'services/translations';
import { parseLongText, formatLongText } from 'services/dataProvider';
import { Subsidiary, getSubsidiary } from 'services/subsidiaries';
import { ConsultantSkillSet } from 'pages/ConsultantSkillSetPdf/types';
import MultiLanguageTextInput from 'components/MultiLanguageTextInput';

const MAX_COMPUTER_SKILLS = 8;
const MAX_HOBBIES = 3;
const MAX_CHARACTERS_BY_LINE = 65;
const MAX_METHODS_AND_TOOLS = 3;
const MAX_STANDARDS = 3;

const MAX_SKILL_LINES: { [key: string]: number } = {
  [Subsidiary.BUYING_PEERS]: 6,
  [Subsidiary.SELLING_PEERS]: 6,
  [Subsidiary.SUPPLY_PEERS]: 6,
  [Subsidiary.FINANCE_PEERS]: 6,
  [Subsidiary.QUALITY_PEERS]: 6,
};

export const OTHER_SKILL_SOURCE: { [key: string]: keyof ConsultantSkillSet } = {
  [Subsidiary.BUYING_PEERS]: 'other_procurement_skills',
  [Subsidiary.SELLING_PEERS]: 'expertises',
  [Subsidiary.SUPPLY_PEERS]: 'other_skills',
  [Subsidiary.FINANCE_PEERS]: 'complementary_informations',
  [Subsidiary.QUALITY_PEERS]: 'other_skills',
};

const validateTextFieldMaxLines = (
  value: string,
  maximumNumberOfLines: number,
  translate: Function,
  errorMessageTraductionKey: string,
) => {
  if (!value) {
    return;
  }

  const lines = value.split('\n');

  if (lines.length > maximumNumberOfLines) {
    return translate(errorMessageTraductionKey, { max: maximumNumberOfLines });
  }

  for (let index = 0; index < lines.length; index++) {
    if (lines[index].length > MAX_CHARACTERS_BY_LINE) {
      return translate('resources.consultantskillsets.fields.line_max_length_exceeded', {
        ligneNumber: index + 1,
        maxCharacters: MAX_CHARACTERS_BY_LINE,
      });
    }
  }
};

const useGetMemoizedValidateMaxErrorOnText = (source: string, maximum: number, translate: any) =>
  useCallback(
    (value: string) =>
      validateTextFieldMaxLines(
        value,
        maximum,
        translate,
        `resources.consultantskillsets.fields.${source}_max_error`,
      ),
    [maximum, source, translate],
  );

const QualityPeersAditionalsInformations = translate(({ translate, ...props }: any) => {
  const validateMethodsAndTools = useGetMemoizedValidateMaxErrorOnText(
    'methods_and_tools',
    MAX_METHODS_AND_TOOLS,
    translate,
  );
  const validateStandards = useGetMemoizedValidateMaxErrorOnText(
    'standards',
    MAX_STANDARDS,
    translate,
  );

  return (
    <>
      <MultiLanguageTextInput
        {...props} // pass props cause it contains "resource" and other variables utils for react-admin
        isLongTextInput
        source="methods_and_tools"
        rows={MAX_METHODS_AND_TOOLS}
        parse={parseLongText}
        format={formatLongText}
        placeholderKey="resources.consultantskillsets.placeholder.QualityPeers.methods_and_tools"
        validate={validateMethodsAndTools}
      />
      <MultiLanguageTextInput
        {...props}
        isLongTextInput
        source="standards"
        rows={MAX_STANDARDS}
        parse={parseLongText}
        format={formatLongText}
        placeholderKey="resources.consultantskillsets.placeholder.QualityPeers.standards"
        validate={validateStandards}
      />
    </>
  );
});

const AdditionalInformationsTab = translate(({ translate, index, isSmall, ...props }: any) => {
  const subsidiary = getSubsidiary();
  const memoizedValidateComputerSkills = useCallback(
    (value: any) =>
      value && value.length > MAX_COMPUTER_SKILLS
        ? translate('resources.consultantskillsets.fields.computer_skills_max_error', {
            max: MAX_COMPUTER_SKILLS,
          })
        : undefined,
    [translate],
  );

  const validateOtherSkills = useGetMemoizedValidateMaxErrorOnText(
    `${OTHER_SKILL_SOURCE[subsidiary]}`,
    MAX_SKILL_LINES[subsidiary],
    translate,
  );
  const validateHobbies = useGetMemoizedValidateMaxErrorOnText('hobbies', MAX_HOBBIES, translate);

  return (
    <FormTab
      label={translate(`consultantskillset.tabs${isSmall ? '.small' : ''}.additional_infos`, {
        index,
      })}
      {...props}
    >
      <MultiLanguageTextInput
        isLongTextInput
        source={`${OTHER_SKILL_SOURCE[subsidiary]}`}
        rows={MAX_SKILL_LINES[subsidiary]}
        parse={parseLongText}
        format={formatLongText}
        placeholderKey={`resources.consultantskillsets.placeholder.${subsidiary}.${
          OTHER_SKILL_SOURCE[subsidiary]
        }`}
        validate={validateOtherSkills}
      />

      <LanguageSkillField consultantskillsetId={props.record && props.record.id} />

      <ReferenceArrayInput
        source="computer_skills"
        reference="computer-skills"
        sort={{ field: `name_${resolveLocale()}`, order: 'ASC' }}
        validate={memoizedValidateComputerSkills}
      >
        <SelectArrayInput optionText={selectLabel} />
      </ReferenceArrayInput>

      {subsidiary === Subsidiary.QUALITY_PEERS && <QualityPeersAditionalsInformations />}
      <MultiLanguageTextInput
        isLongTextInput
        source="hobbies"
        rows={MAX_HOBBIES}
        parse={parseLongText}
        format={formatLongText}
        placeholderKey="resources.consultantskillsets.placeholder.hobbies"
        validate={validateHobbies}
      />
    </FormTab>
  );
});

export default AdditionalInformationsTab;
