import React from 'react';
import { Experience } from '../types';
import { formatDate } from 'services/date';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { colorPalette } from 'stylesheet';
import { getCompleteLogo } from 'services/logoProvider';
import { Subsidiary, getSubsidiary } from 'services/subsidiaries';

interface ExperienceHeaderProps {
  experience: Experience;
  classes: any;
}

const styles = ({ palette }: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      color: colorPalette.lightText,
      height: '12mm',
      paddingBottom: '1mm',
      marginBottom: '5mm',
    },
    bpHeader: {
      borderBottom: `solid  ${palette.secondary.main} 0.5mm`,
    },
    notBpHeader: {
      borderBottom: `solid  ${palette.primary.main} 0.5mm`,
    },
    logos: {
      flex: '1 1 0',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    companyLogo: {
      height: '10mm',
    },
    bpLogo: {
      height: '10mm',
      marginLeft: '3mm',
    },
    title: {
      flex: '1 1 0',
      fontSize: '16pt',
      textTransform: 'capitalize',
    },
    dates: {
      flex: '1 1 0',
      fontSize: '12pt',
      textAlign: 'right',
    },
  });

const IS_CLIENT_LOGO_VISIBLE: { [key: string]: boolean } = {
  [Subsidiary.BUYING_PEERS]: true,
  [Subsidiary.SELLING_PEERS]: true,
  [Subsidiary.SUPPLY_PEERS]: true,
  [Subsidiary.FINANCE_PEERS]: false,
  [Subsidiary.SUPPLY_PEERS]: true,
};

const showClient = (subsidiary: string, experience: Experience) =>
  IS_CLIENT_LOGO_VISIBLE[subsidiary] || !experience.as_internal_consultant;

const ExperienceHeader = ({ classes, experience }: ExperienceHeaderProps) => {
  const subsidiary = getSubsidiary();
  return (
    <div
      className={`${classes.header} ${
        experience.as_internal_consultant ? classes.bpHeader : classes.notBpHeader
      }`}
    >
      <div className={classes.title}>{experience.title}</div>
      <div className={classes.logos}>
        {showClient(subsidiary, experience) && experience.company ? (
          experience.company.logo ? (
            <img src={experience.company.logo} alt="Company Logo" className={classes.companyLogo} />
          ) : (
            <span>{experience.company.name}</span>
          )
        ) : null}
        {experience.as_internal_consultant ? (
          <img
            src={getCompleteLogo()}
            alt={`${subsidiary} Logo`}
            className={showClient(subsidiary, experience) ? classes.bpLogo : classes.companyLogo}
          />
        ) : null}
      </div>
      <div className={classes.dates}>
        {`${experience.start_date &&
          formatDate(new Date(experience.start_date), 'LLLL u')} - ${experience.end_date &&
          formatDate(new Date(experience.end_date), 'LLLL u')}`}
      </div>
    </div>
  );
};

export default withStyles(styles)(ExperienceHeader);
