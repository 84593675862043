import { createMuiTheme } from '@material-ui/core/styles';

export const colorPalette = {
  gray: '#b5ac8c',
  darkText: '#5f5f5f',
  lightText: '#b2b2b2',
};

export const pageStyle = {
  height: '209mm', // pages have "pageBreakAfter: always" property that ensure correct break page
  width: '297mm',
  fontFamily: 'Franklin Gothic Book',
  fontSize: '10pt',
  overflow: 'hidden',
};

const peersGroupTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#335461',
      main: '#002A3A',
      dark: '#001D28',
      contrastText: '#fff',
    },
    secondary: {
      light: '#BEAB7F',
      main: '#AE975F',
      dark: '#796942',
      contrastText: '#000',
    },
  },
});

export const getCustomTheme = () => {
  return peersGroupTheme;
};
