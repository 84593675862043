import React from 'react';
import { Experience } from '../types';
import { translate } from 'react-admin';
import { createStyles, withStyles } from '@material-ui/core/styles';
import DOMPurify from 'dompurify';
import { selectLabel } from 'services/translations';

interface RightColumnProps {
  experience: Experience;
  classes: any;
  translate: any;
}

const styles = createStyles({
  label: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  secondLabel: {
    marginTop: '3mm',
  },
  liste: {
    '& li': {
      listStyle: 'disc',
      lineHeight: 1.1,
    },
    '& ul': {
      paddingLeft: '2rem',
    },
  },
  leverContent: {
    paddingLeft: '2rem',
  },
});

const ExperienceRightColumn = ({ classes, experience, translate }: RightColumnProps) => {
  const procurement_levers =
    experience.procurement_levers &&
    experience.procurement_levers.length > 0 &&
    experience.procurement_levers.map(selectLabel).join(' - ');

  return (
    <>
      <div className={classes.label}>
        {translate('consultantskillset.pdf.experiences.subtitle.realisations')}
      </div>
      {experience.realisations ? (
        <div
          className={classes.liste}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(experience.realisations) }}
        />
      ) : (
        <div />
      )}
      {procurement_levers && (
        <>
          <div className={`${classes.label} ${classes.secondLabel}`}>
            {translate('consultantskillset.pdf.experiences.subtitle.procurement_levers')}
          </div>
          <div className={classes.leverContent}>{procurement_levers}</div>
        </>
      )}
      {experience.result && (
        <>
          <div className={`${classes.label} ${classes.secondLabel}`}>
            {translate('consultantskillset.pdf.experiences.subtitle.result')}
          </div>
          <div
            className={classes.liste}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(experience.result) }}
          />
        </>
      )}
    </>
  );
};

export default translate(withStyles(styles)(ExperienceRightColumn));
