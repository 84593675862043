import React from 'react';
import { required, maxLength, translate } from 'react-admin';
import {
  PerimeterInput,
  ProcurementProcessesInput,
  ProcurementCategoriesInput,
  ProcurementLeversInput,
} from './ExperienceCustomInputs';
import { withStyles } from '@material-ui/core';
import { containerStyles } from 'components/customFormStyle';
import MultiLanguageTextInput from 'components/MultiLanguageTextInput';
import { addErrorsNumberOfLinesForMultilanguageInput } from 'services/errorHandler';

const MAX_NUMBER_OF_CATEGORIES = 5;

const MAX_NUMBER_OF_REALISATIONS = 8;

export const validateBuyingPeersExperience = (translate: any) => (values: any) => {
  const errors: any = {};
  if (
    values.procurement_categories &&
    values.procurement_categories.length > MAX_NUMBER_OF_CATEGORIES
  ) {
    errors.procurement_categories = [
      translate('resources.experiences.fields.procurement_categories_max_error', {
        maxNumber: MAX_NUMBER_OF_CATEGORIES,
      }),
    ];
  }

  addErrorsNumberOfLinesForMultilanguageInput({
    errors,
    values,
    source: 'realisations',
    maximum: MAX_NUMBER_OF_REALISATIONS,
    messageKey: 'resources.experiences.fields.realisations_max_error',
    translate,
  });
  return errors;
};

export const BuyingPeersInputs = translate(
  withStyles(containerStyles)(({ translate, classes, ...props }: any) => (
    <div className={classes.container}>
      <MultiLanguageTextInput
        isLongTextInput
        source="mission"
        placeholderKey="resources.experiences.placeholder.BuyingPeers.mission"
        validate={[required(), maxLength(255)]}
        rows="2"
        {...props}
      />

      <MultiLanguageTextInput
        source="stakes"
        placeholderKey="resources.experiences.placeholder.BuyingPeers.stakes"
        validate={[required(), maxLength(255)]}
        {...props}
      />

      <PerimeterInput {...props} />

      <ProcurementProcessesInput {...props} />

      <ProcurementCategoriesInput {...props} />

      <ProcurementLeversInput {...props} />
    </div>
  )),
);
