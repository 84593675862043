import React from 'react';
import TextField from '@material-ui/core/TextField';

// see http://redux-form.com/6.4.3/examples/material-ui/
export const renderInput = ({
  meta: { touched, error } = { touched: false, error: '' },
  input,
  ...props
}: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...input}
    {...props}
    fullWidth
  />
);
