import { formatLongText } from './dataProvider';

const languages = ['en', 'fr', 'de'];
const MAX_CHARACTERS_BY_LINE = 65;
export const hasBadNumberOfLines = (
  { lines, minimum, maximum }: any = { lines: '', minimum: -1, maximum: -1 },
) =>
  lines &&
  ((minimum >= 0 && lines.split('\n').length < minimum) ||
    (maximum >= 0 && lines.split('\n').length > maximum));

export const hasBadNumberOfLinesMultilanguageInput = (
  { values, source, minimum, maximum }: any = { lines: '', minimum: -1, maximum: -1 },
) =>
  // return the list of languages containing an error on number of lines
  languages.filter((language: string) => {
    const lines = values && values[`${source}_${language}`];
    if (
      lines &&
      ((minimum >= 0 && lines.split('\n').length < minimum) ||
        (maximum >= 0 && lines.split('\n').length > maximum))
    ) {
      return true;
    }
    return false;
  });

export const hasTooLongLineMultilanguageInput = (
  { values, source }: any = { lines: '', minimum: -1, maximum: -1 },
) =>
  // return the list of languages containing an error on size of lines
  languages.filter((language: string) => {
    const lines = values && formatLongText(values[`${source}_${language}`]).split('\n');
    for (let index = 0; index < lines.length; index++) {
      if (lines && lines[index].length > MAX_CHARACTERS_BY_LINE) {
        return true;
      }
    }
    return false;
  });

export const addErrorsNumberOfLinesForMultilanguageInput = ({
  errors,
  values,
  source,
  minimum,
  maximum,
  messageKey,
  translate,
}: any) => {
  const languagesWithBadNumberOfLines = hasBadNumberOfLinesMultilanguageInput({
    values,
    source,
    minimum,
    maximum,
  });
  const languagesWithTooLongLines = hasTooLongLineMultilanguageInput({ source, values });

  if (languagesWithBadNumberOfLines.length > 0) {
    languages.forEach((language: string) => {
      // since we don't know which input is currently displayed, we need to add the error on each of them
      const message = translate(messageKey, {
        language: languagesWithBadNumberOfLines
          .map((language: string) => language.toUpperCase()) // this line precise in the message which languages are concerned
          .join(', '),
        minNumber: minimum,
        maxNumber: maximum,
      });
      errors[`${source}_${language}`] = errors[`${source}_${language}`]
        ? errors[`${source}_${language}`].push(message)
        : [message];
    });
  } else if (languagesWithTooLongLines.length > 0) {
    languages.forEach((language: string) => {
      // since we don't know which input is currently displayed, we need to add the error on each of them
      const message = translate('resources.experiences.fields.line_max_length_exceeded', {
        language: languagesWithTooLongLines
          .map((language: string) => language.toUpperCase()) // this line precise in the message which languages are concerned
          .join(', '),
        maxCharacters: MAX_CHARACTERS_BY_LINE,
      });
      errors[`${source}_${language}`] = errors[`${source}_${language}`]
        ? errors[`${source}_${language}`].push(message)
        : [message];
    });
  }
};
