import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-admin';
import FileSaver from 'file-saver';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import { API_URL } from 'services/dataProvider';
import { format } from 'date-fns';
import { saveFormat } from 'services/date';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getSubsidiary } from 'services/subsidiaries';
import { toast } from 'react-toastify';

const downloadFile = (url: string, fileName: string) => {
  const token = localStorage.getItem('token');
  const options = {
    headers: new Headers({ Authorization: `Bearer ${token}` }),
  };

  return fetch(url, options)
    .then(response => {
      if (response.ok) {
        return response.blob();
      }
      throw new Error('Backend failed to generate the consultant skill set PDF');
    })
    .then(file => {
      FileSaver.saveAs(file, fileName);
    })
    .catch(() => {
      toast.error('Something when wrong when generating the PDF. Please try again');
    });
};

const DownloadButton = ({ record, language }: any) => {
  const subsidiary = getSubsidiary();
  const [loading, setLoading] = useState(false);
  const downloadConsultantSkillSetPdf = async () => {
    setLoading(true);
    await downloadFile(
      `${API_URL}/api/consultantskillsets/${record.id}/pdf/${language}/${subsidiary}`,
      `Peers Group - ${record.user.first_name} ${record.user.last_name
        .charAt(0)
        .toUpperCase()} - ${language.toUpperCase()} - ${format(new Date(), saveFormat)}`,
    );
    setLoading(false);
  };

  return (
    <Button onClick={loading ? undefined : downloadConsultantSkillSetPdf}>
      {loading ? <CircularProgress size={24} /> : <PdfIcon />}
    </Button>
  );
};

const mapStateToProps = (state: any) => {
  return { language: state.exportLanguage };
};

export default connect(mapStateToProps)(DownloadButton);
