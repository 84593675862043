import React from 'react';

import { Subsidiary, getSubsidiary } from 'services/subsidiaries';
import { AutocompleteInput } from 'react-admin';

const seniorityChoices = (subsidiary: Subsidiary) => [
  {
    id: 'Junior',
    name: 'consultantskillset.pdf.header.status.junior',
    explanation: `resources.consultantskillsets.placeholder.${subsidiary}.junior`,
  },
  {
    id: 'Confirmed',
    name: 'consultantskillset.pdf.header.status.confirmed',
    explanation: `resources.consultantskillsets.placeholder.${subsidiary}.confirmed`,
  },
  {
    id: 'Senior',
    name: 'consultantskillset.pdf.header.status.senior',
    explanation: `resources.consultantskillsets.placeholder.${subsidiary}.senior`,
  },
];

const optionRenderer = (choice: any) => choice.explanation;

const SeniorityForm = (props: any) => {
  const subsidiary = getSubsidiary();
  return (
    <AutocompleteInput
      source="seniority"
      choices={seniorityChoices(subsidiary)}
      optionText={optionRenderer}
      {...props}
    />
  );
};

export default SeniorityForm;
