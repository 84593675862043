import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import { API_URL } from 'services/dataProvider';

const clearAuthLocalStorageInfo = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('isBusinessManager');
  localStorage.removeItem('ownConsultantSkillSet');
  localStorage.removeItem('ownSubsidiary');
};

export default () => async (type: any, params: any) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;

    const request = new Request(`${API_URL}/auth/jwt/create/`, {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const jsonResponse = await response.json();
    const { access, is_business_manager, consultant_skill_set, subsidiary } = jsonResponse;

    localStorage.setItem('token', access);
    localStorage.setItem('isBusinessManager', is_business_manager);
    localStorage.setItem('ownConsultantSkillSet', consultant_skill_set);

    // We need to use both localStorage and component state to :
    //  - force the app to rerender with new theme when subsidiary change (local state)
    //  - have access to it every where (local storage)
    localStorage.setItem('ownSubsidiary', subsidiary);

    return Promise.resolve();
  }

  if (type === AUTH_LOGOUT || type === AUTH_ERROR) {
    clearAuthLocalStorageInfo();
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    if (localStorage.getItem('token')) {
      return Promise.resolve();
    }

    return Promise.reject();
  }

  return Promise.reject('Unknown method');
};
