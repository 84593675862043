import React from 'react';
import { Create } from 'react-admin';
import { parse } from 'query-string';
import LanguageSkillForm from './LanguageSkillForm';
import BackButton from 'components/BackButton';

const LanguageSkillCreate = (props: any) => {
  const { consultantskillset } = parse(props.location.search);
  const redirect = `/consultantskillsets/${consultantskillset}/4`;

  return (
    <Create {...props} actions={<BackButton goTo={redirect} />}>
      <LanguageSkillForm redirect={redirect} location={props.location} />
    </Create>
  );
};

export default LanguageSkillCreate;
