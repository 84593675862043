import React from 'react';
import { withStyles, createStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import { colorPalette } from 'stylesheet';

const styles = createStyles({
  container: {
    textAlign: 'center',
    color: colorPalette.lightText,
    fontSize: '8pt',
  },
});

const Footer = ({ classes, translate }: any) => {
  return (
    <div className={classes.container}>
      <p>{translate('consultantskillset.pdf.footer.firstline')}</p>
      <p>{translate('consultantskillset.pdf.footer.secondline')}</p>
    </div>
  );
};

export default translate(withStyles(styles)(Footer));
