import React from 'react';
import {
  DateField,
  Labeled,
  translate,
  EditButton,
  DeleteButton,
  Link,
  Button,
  BooleanField,
} from 'react-admin';
import { Card, CardContent, createStyles, withStyles, Typography } from '@material-ui/core';
import cardStyles from 'components/Card';
import AddIcon from '@material-ui/icons/Add';
import { getSimpleLogo } from 'services/logoProvider';
import { format } from 'date-fns';
import { saveFormat } from 'services/date';
import { selectLabel } from 'services/translations';

const styles = createStyles({
  ...cardStyles,
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  logo: { width: '30%', marginLeft: '1rem' },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    boxSizing: 'border-box',
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  asInternalConsultantImage: {
    height: '8mm',
  },
});

const AddNewExperienceButton = ({ consultantskillsetId }: any) => (
  <Button
    component={Link}
    to={{
      pathname: '/experiences/create',
      search: `?consultantskillset=${consultantskillsetId}`,
    }}
    label="resources.experiences.add"
  >
    <AddIcon />
  </Button>
);

const EditExperienceButton = ({ consultantskillsetId, experienceId }: any) => (
  <EditButton
    component={Link}
    to={{
      pathname: `/experiences/${experienceId}`,
      search: `?consultantskillset=${consultantskillsetId}`,
    }}
  />
);

const ExperienceCards = ({ ids, data, translate, classes, consultantskillsetId }: any) => (
  <>
    <div className={classes.container}>
      {ids &&
        ids
          .sort((id1: string, id2: string) =>
            data[id1].start_date > data[id2].start_date ? -1 : 1,
          )
          .map((id: string) => {
            const record = data[id];
            record.end_date = record.is_current ? format(new Date(), saveFormat) : record.end_date;
            return (
              <Card className={classes.card} key={id}>
                <CardContent className={classes.content}>
                  <header className={classes.header}>
                    <Typography className={classes.subtitle}>
                      {selectLabel({
                        name_en: record.title_en,
                        name_fr: record.title_fr,
                        name_de: record.title_de,
                      })}
                    </Typography>
                    {record.company && record.company.logo && (
                      <img className={classes.logo} src={record.company.logo} alt="Logo" />
                    )}
                  </header>
                  <div className={classes.body}>
                    <div className={classes.line}>
                      <Labeled
                        className={classes.field}
                        label={translate('resources.experiences.fields.start_date')}
                      >
                        <DateField
                          record={record}
                          source="start_date"
                          options={{ year: 'numeric', month: 'long' }}
                        />
                      </Labeled>
                      <Labeled
                        className={classes.field}
                        label={translate('resources.experiences.fields.end_date')}
                      >
                        <DateField
                          record={record}
                          source="end_date"
                          options={{ year: 'numeric', month: 'long' }}
                        />
                      </Labeled>
                    </div>
                    <div className={classes.line}>
                      {record && record['as_internal_consultant'] ? (
                        <img
                          src={getSimpleLogo()}
                          alt="Logo"
                          className={classes.asInternalConsultantImage}
                        />
                      ) : (
                        <div />
                      )}
                      <Labeled
                        className={classes.field}
                        label={translate('resources.experiences.should_detail_be_displayed')}
                      >
                        <BooleanField record={record} source="should_detail_be_displayed" />
                      </Labeled>
                    </div>

                    <div className={classes.actionButtons}>
                      <EditExperienceButton
                        experienceId={id}
                        consultantskillsetId={consultantskillsetId}
                      />
                      <DeleteButton resource="experiences" record={record} />
                    </div>
                  </div>
                </CardContent>
              </Card>
            );
          })}
    </div>
    <AddNewExperienceButton consultantskillsetId={consultantskillsetId} />
  </>
);

export default translate(withStyles(styles)(ExperienceCards));
