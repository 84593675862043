export const isBusinessManager = (): any => {
  const businessManager = localStorage.getItem('isBusinessManager');

  if (businessManager === null) {
    return null;
  }

  // Json parse to cast to boolean
  return JSON.parse(businessManager);
};
