import React from 'react';
import { FullPageForm } from 'components/CustomLogin';
import SignupForm from './SignupForm';

const Signup = (props: any) => (
  <FullPageForm {...props}>
    <SignupForm />
  </FullPageForm>
);

export default Signup;
