import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import CustomLayout from 'components/CustomLayout';
import CustomLogin from 'components/CustomLogin';
import exportLanguageReducer from 'redux/exportLanguageReducer';
import dataProvider from 'services/dataProvider';
import { getCustomTheme } from 'stylesheet';

import {
  Company,
  ConsultantSkillSet,
  ConsultantSkillSetPdf,
  EnumRessources,
  Experience,
  Formation,
  InitialRedirect,
  School,
  Signup,
  Portfolio,
  LanguageSkill,
  InterventionDomain,
  MainSkill,
  ResetPassword,
  RequestPassword,
} from './pages';
import authProvider from './authProvider';

// import translations
import { i18nProvider, resolveLocale } from 'services/translations';

const App = () => {
  return (
    <Admin
      appLayout={CustomLayout}
      authProvider={authProvider()}
      dashboard={InitialRedirect}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      locale={resolveLocale()}
      loginPage={CustomLogin}
      theme={getCustomTheme()}
      title="Buying Peers"
      customRoutes={[
        <Route
          exact
          path="/consultantskillsets/:id/render/:subsidiary"
          render={(props: any) => <ConsultantSkillSetPdf {...props} dataProvider={dataProvider} />}
          noLayout
        />,
        <Route
          exact
          path="/consultantskillsets/:id/render"
          render={(props: any) => <ConsultantSkillSetPdf {...props} dataProvider={dataProvider} />}
          noLayout
        />,
        <Route exact path="/signup" render={(props: any) => <Signup {...props} />} noLayout />,
        <Route
          exact
          path="/password/reset/confirm/:uid/:token"
          render={(props: any) => <ResetPassword {...props} />}
          noLayout
        />,
        <Route
          exact
          path="/request-password"
          render={(props: any) => <RequestPassword {...props} />}
          noLayout
        />,
      ]}
      customReducers={{ exportLanguage: exportLanguageReducer }}
    >
      <Resource {...Company} />
      <Resource {...ConsultantSkillSet} />
      <Resource {...Experience} />
      <Resource {...Formation} />
      <Resource {...LanguageSkill} />
      <Resource {...Portfolio} />
      <Resource {...School} />
      <Resource {...InterventionDomain} />
      <Resource {...MainSkill} />
      {EnumRessources.map((resource: string) => (
        <Resource name={resource} key={resource} />
      ))}
    </Admin>
  );
};

export default App;
