import React from 'react';
import { Create } from 'react-admin';
import { parse } from 'query-string';
import BackButton from 'components/BackButton';
import FormationForm from './FormationForm';

const FormationCreate = ({ ...props }: any) => {
  const { consultantskillset } = parse(props.location.search);
  const redirect = `/consultantskillsets/${consultantskillset}/1`;

  return (
    <Create {...props} actions={<BackButton goTo={redirect} />}>
      <FormationForm redirect={redirect} location={props.location} />
    </Create>
  );
};

export default FormationCreate;
