import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { ConsultantSkillSet } from './types';
import { translate } from 'react-admin';
import { colorPalette, pageStyle } from 'stylesheet';
import { formatDate } from 'services/date';
import { getCompleteLogo } from 'services/logoProvider';
import { Subsidiary, getSubsidiary } from 'services/subsidiaries';

const styles = ({ palette }: Theme) =>
  createStyles({
    page: {
      ...pageStyle,
      pageBreakAfter: 'always',
      padding: '0 5mm 0 5mm',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      boxSizing: 'border-box',
    },
    container: {
      marginBottom: '35mm',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    summaryAndPhoto: {
      marginBottom: '35mm',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginLeft: '1cm',
    },
    description: {
      width: '150mm',
      textAlign: 'justify',
      height: 'max-content',
      margin: 'auto 0',
    },
    picture: {
      width: '4cm',
      height: '4cm',
      borderRadius: '50%',
      filter: 'grayscale(1)',
    },
    textContainer: {
      flexGrow: 1,
      marginRight: '5mm',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    textPrimaryBorder: {
      borderBottom: `solid ${palette.primary.dark} 1mm`,
    },
    textSecondaryBorder: {
      borderBottom: `solid ${palette.secondary.main} 1mm`,
    },
    firstLine: {
      fontSize: '20pt',
      marginBottom: '2mm',
      textDecoration: 'bold',
      color: palette.secondary.main,
    },
    secondLine: {
      fontSize: '30pt',
      marginBottom: '4mm',
      textTransform: 'capitalize',
      color: palette.primary.main,
    },
    thirdLine: {
      fontSize: '15pt',
      marginBottom: '2mm',
      textTransform: 'capitalize',
      color: colorPalette.lightText,
    },
    logo: {
      height: '3.5cm',
      alignSelf: 'flex-end',
    },
  });

interface FirstPageProps {
  consultantSkillSet: ConsultantSkillSet;
  classes: any;
  translate: any;
}

const IS_DESCRIPTION_VISIBLE: { [key: string]: boolean } = {
  [Subsidiary.BUYING_PEERS]: false,
  [Subsidiary.SELLING_PEERS]: true,
  [Subsidiary.SUPPLY_PEERS]: true,
  [Subsidiary.FINANCE_PEERS]: false,
  [Subsidiary.QUALITY_PEERS]: false,
};

const IS_DATED: { [key: string]: boolean } = {
  [Subsidiary.BUYING_PEERS]: true,
  [Subsidiary.SELLING_PEERS]: true,
  [Subsidiary.SUPPLY_PEERS]: true,
  [Subsidiary.FINANCE_PEERS]: false,
  [Subsidiary.QUALITY_PEERS]: true,
};

const IS_PRIMARY_BORDER: { [key: string]: boolean } = {
  [Subsidiary.BUYING_PEERS]: true,
  [Subsidiary.SELLING_PEERS]: false,
  [Subsidiary.SUPPLY_PEERS]: false,
  [Subsidiary.FINANCE_PEERS]: false,
  [Subsidiary.QUALITY_PEERS]: false,
};

const FirstPage = ({ classes, consultantSkillSet, translate }: FirstPageProps) => {
  const subsidiary = getSubsidiary();

  return (
    <div className={classes.page}>
      <div className={classes.summaryAndPhoto}>
        {consultantSkillSet.picture && consultantSkillSet.display_picture ? (
          <img src={consultantSkillSet.picture} alt="" className={classes.picture} />
        ) : (
          <div />
        )}
        {IS_DESCRIPTION_VISIBLE[subsidiary] && (
          <p className={classes.description}>{consultantSkillSet.summary}</p>
        )}
      </div>
      <div className={classes.container}>
        <div
          className={`${classes.textContainer} ${
            IS_PRIMARY_BORDER[subsidiary] ? classes.textPrimaryBorder : classes.textSecondaryBorder
          }`}
        >
          <p className={classes.firstLine}>
            {consultantSkillSet.seniority
              ? `${translate(
                  `consultantskillset.pdf.header.status.${consultantSkillSet.seniority.toLowerCase()}`,
                )} - `
              : ''}
            {consultantSkillSet.job_title && `${consultantSkillSet.job_title} - `}
            {consultantSkillSet.years_of_experience
              ? translate(
                  'consultantskillset.pdf.header.years_of_experience',
                  consultantSkillSet.years_of_experience,
                )
              : ''}
          </p>
          <p className={classes.secondLine}>
            {`${consultantSkillSet.user.first_name} ${consultantSkillSet.user.last_name
              .charAt(0)
              .toUpperCase()}.`}
          </p>
          {IS_DATED[subsidiary] && (
            <p className={classes.thirdLine}>{formatDate(new Date(), 'PPPP')}</p>
          )}
        </div>
        <img src={getCompleteLogo()} className={classes.logo} alt="logo" />
      </div>
    </div>
  );
};

export default translate(withStyles(styles)(FirstPage));
