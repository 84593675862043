import React from 'react';
import { translate, ReferenceInput, AutocompleteInput, required } from 'react-admin';
import { parseLongText, formatLongText } from 'services/dataProvider';
import { selectLabel } from 'services/translations';
import { addErrorsNumberOfLinesForMultilanguageInput } from 'services/errorHandler';
import { withStyles } from '@material-ui/core';
import { containerStyles } from 'components/customFormStyle';
import MultiLanguageTextInput from 'components/MultiLanguageTextInput';

const NUMBER_OF_OBJECTIVES_LINES = 5;
const MIN_NUMBER_OF_OBJECTIVES = 3;

const NUMBER_OF_MISSIONS_LINES = 5;
const MIN_NUMBER_OF_MISSIONS = 3;

const MIN_NUMBER_OF_REALISATIONS = 3;

const NUMBER_OF_RESULT_LINES = 2;

export const validateSupplyPeersExperience = (translate: any) => (values: any) => {
  const errors: any = {};

  addErrorsNumberOfLinesForMultilanguageInput({
    errors,
    values,
    source: 'missions',
    minimum: MIN_NUMBER_OF_MISSIONS,
    messageKey: 'resources.experiences.fields.missions_min_error',
    translate,
  });

  addErrorsNumberOfLinesForMultilanguageInput({
    errors,
    values,
    source: 'objectives',
    minimum: MIN_NUMBER_OF_OBJECTIVES,
    messageKey: 'resources.experiences.fields.objectives_min_error',
    translate,
  });

  addErrorsNumberOfLinesForMultilanguageInput({
    errors,
    values,
    source: 'realisations',
    minimum: MIN_NUMBER_OF_REALISATIONS,
    messageKey: 'resources.experiences.fields.realisations_min_error',
    translate,
  });

  return errors;
};

export const SupplyPeersInputs = translate(
  withStyles(containerStyles)(({ translate, classes, ...props }: any) => (
    <div className={classes.container}>
      <ReferenceInput
        {...props}
        source="activityArea.id"
        reference="activity-area"
        label={translate('resources.experiences.fields.activityArea.id')}
        validate={required()}
      >
        <AutocompleteInput optionText={selectLabel} />
      </ReferenceInput>
      <MultiLanguageTextInput
        isLongTextInput
        source="missions"
        rows={NUMBER_OF_MISSIONS_LINES}
        parse={parseLongText}
        format={formatLongText}
        validate={required()}
        placeholderKey="resources.experiences.placeholder.SupplyPeers.missions"
        {...props}
      />
      <MultiLanguageTextInput
        isLongTextInput
        source="objectives"
        rows={NUMBER_OF_OBJECTIVES_LINES}
        parse={parseLongText}
        format={formatLongText}
        validate={required()}
        placeholderKey="resources.experiences.placeholder.SupplyPeers.objectives"
        {...props}
      />
      <MultiLanguageTextInput
        isLongTextInput
        source="result"
        rows={NUMBER_OF_RESULT_LINES}
        parse={parseLongText}
        format={formatLongText}
        placeholderKey="resources.experiences.placeholder.SupplyPeers.result"
        {...props}
      />
    </div>
  )),
);
