import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { translate } from 'react-admin';
import { colorPalette } from 'stylesheet';
import { Portfolio } from './types';
import BlockTitle from 'components/BlockTitle';
import { selectLabel } from 'services/translations';

interface PortfolioProps {
  portfolios: Portfolio[];
  classes: any;
  translate: any;
}
const styles = ({ palette }: Theme) =>
  createStyles({
    tableContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      marginTop: '3mm',
    },
    table: {
      width: '100%',
      '& td': {
        verticalAlign: 'bottom',
      },
    },
    firstEmptyCol: {
      width: '2mm',
    },
    emptyCol: {
      width: '2mm',
    },
    lastEmptyCol: {
      width: '5mm',
    },
    headerUnderline: {
      borderBottom: `solid ${palette.primary.main} 1mm`,
      paddingBottom: '2mm',
      fontSize: '11pt',
    },
    secondHeader: {
      '& $headerUnderline': {
        paddingTop: '6mm',
      },
    },
    dataRow: {
      '& td': {
        padding: '4mm 0 2mm 0',
        whiteSpace: 'nowrap',
      },
    },
    dataUnderline: {
      borderBottom: `solid ${colorPalette.lightText} 0.5mm`,
    },
    bold: {
      fontWeight: 'bold',
      color: palette.primary.dark,
    },
    toCenter: {
      textAlign: 'center',
    },
  });

const Rows = ({ data, translate, classes }: any) =>
  data.map((portfolio: Portfolio) => (
    <tr className={classes.dataRow}>
      <td className={classes.firstEmptyCol} />
      <td className={`${classes.dataUnderline} ${classes.bold}`}>
        {selectLabel(portfolio.procurement_category)}
      </td>
      <td className={`${classes.emptyCol} ${classes.dataUnderline}`} />
      <td className={`${classes.toCenter} ${classes.dataUnderline}`}>
        {parseFloat(portfolio.tactical_buy) > 0
          ? translate('consultantskillset.pdf.portfolio.years', {
              smart_count: parseFloat(portfolio.tactical_buy),
            })
          : null}
      </td>
      <td className={`${classes.emptyCol} ${classes.dataUnderline}`} />
      <td className={`${classes.toCenter} ${classes.dataUnderline}`}>
        {parseFloat(portfolio.project_purchasing) > 0
          ? translate('consultantskillset.pdf.portfolio.years', {
              smart_count: parseFloat(portfolio.project_purchasing),
            })
          : null}
      </td>
      <td className={`${classes.emptyCol} ${classes.dataUnderline}`} />
      <td className={`${classes.toCenter} ${classes.dataUnderline}`}>
        {parseFloat(portfolio.category_management) > 0
          ? translate('consultantskillset.pdf.portfolio.years', {
              smart_count: parseFloat(portfolio.category_management),
            })
          : null}
      </td>
      <td className={`${classes.emptyCol} ${classes.dataUnderline}`} />
      <td className={`${classes.dataUnderline} ${classes.bold}`}>
        {parseFloat(portfolio.total_years_of_experience) > 0
          ? translate('consultantskillset.pdf.portfolio.years', {
              smart_count: parseFloat(portfolio.total_years_of_experience),
            })
          : null}
      </td>
      <td className={classes.lastEmptyCol}> </td>
    </tr>
  ));

const PortfolioComponent = ({ classes, portfolios, translate }: PortfolioProps) => {
  if (!portfolios) {
    return null;
  }
  const direct = portfolios.filter(
    (portfolio: Portfolio) => portfolio.procurement_category.is_direct,
  );
  const indirect = portfolios.filter(
    (portfolio: Portfolio) => !portfolio.procurement_category.is_direct,
  );

  if (direct.length === 0 && indirect.length === 0) {
    return <BlockTitle title={translate('consultantskillset.pdf.portfolio.title')} />;
  }

  return (
    <>
      <BlockTitle title={translate('consultantskillset.pdf.portfolio.title')} />
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td colSpan={2} className={classes.headerUnderline}>
                {direct.length > 0
                  ? translate('consultantskillset.pdf.portfolio.direct')
                  : translate('consultantskillset.pdf.portfolio.indirect')}
              </td>
              <td />
              <td className={`${classes.toCenter} ${classes.headerUnderline}`}>
                {translate('consultantskillset.pdf.portfolio.tactic')}
              </td>
              <td />
              <td className={`${classes.toCenter} ${classes.headerUnderline}`}>
                {translate('consultantskillset.pdf.portfolio.project')}
              </td>
              <td />
              <td className={`${classes.toCenter} ${classes.headerUnderline}`}>
                {translate('consultantskillset.pdf.portfolio.management')}
              </td>
              <td />
              <td colSpan={2} className={classes.headerUnderline} />
            </tr>

            <Rows data={direct} classes={classes} translate={translate} />

            {direct.length > 0 && indirect.length > 0 ? (
              <tr className={classes.secondHeader}>
                <td colSpan={2} className={classes.headerUnderline}>
                  {translate('consultantskillset.pdf.portfolio.indirect')}
                </td>
                <td />
                <td className={classes.headerUnderline}> </td>
                <td />
                <td className={classes.headerUnderline}> </td>
                <td />
                <td className={classes.headerUnderline} />
                <td />
                <td className={classes.headerUnderline}> </td>
                <td className={classes.headerUnderline}> </td>
              </tr>
            ) : null}

            <Rows data={indirect} classes={classes} translate={translate} />
          </tbody>
        </table>
      </div>
    </>
  );
};

export default translate(withStyles(styles)(PortfolioComponent));
