import React from 'react';
import { withStyles, createStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import { colorPalette } from 'stylesheet';
import ExperienceHeader from './ExperienceHeader';
import ExperienceLeftColumn from './ExperienceLeftColumn';
import ExperienceRightColumn from './ExperienceRightColumn';
import { Experience } from '../types';

interface DetailedExperienceProps {
  experience: Experience;
  classes: any;
}

const styles = createStyles({
  container: {
    width: '100%',
    flexGrow: 1,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    color: colorPalette.darkText,
  },
  column: {
    flexGrow: 1,
    maxWidth: '50%',
  },
  rightColumn: {
    paddingLeft: '3mm',
  },
  leftColumn: {
    paddingRight: '3mm',
  },
});

const DetailedExperience = ({ classes, experience }: DetailedExperienceProps) => {
  if (!experience) {
    return <div className={classes.container} />;
  }

  return (
    <div className={classes.container}>
      <ExperienceHeader experience={experience} />
      <div className={classes.content}>
        <div className={`${classes.column} ${classes.leftColumn}`}>
          <ExperienceLeftColumn experience={experience} />
        </div>
        <div className={`${classes.column} ${classes.rightColumn}`}>
          <ExperienceRightColumn experience={experience} />
        </div>
      </div>
    </div>
  );
};

export default translate(withStyles(styles)(DetailedExperience));
