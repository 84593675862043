import { resolveBrowserLocale } from 'react-admin';

import deTranslations from 'translations/de.json';
import enTranslations from 'translations/en.json';
import frTranslations from 'translations/fr.json';

import deutschMessages from 'ra-language-german';
import englishMessages from 'ra-language-english';
import frenchMessages from 'ra-language-french';

import deepmerge from 'deepmerge';

// Translations config
const messages: { [key: string]: any } = {
  en: deepmerge.all([englishMessages, enTranslations]),
  de: deepmerge.all([deutschMessages, enTranslations, deTranslations]),
  fr: deepmerge.all([frenchMessages, enTranslations, frTranslations]),
};
const ERROR_MESSAGE = '';

// Usefull functions
export const i18nProvider = (locale: string) => {
  if (locale in messages) {
    return messages[locale];
  }
  return messages.en;
};

export const resolveLocale = () => {
  const browserLocale = resolveBrowserLocale();

  if (browserLocale in messages) {
    return browserLocale;
  }
  return 'en';
};

export const selectLabel = (choice: any) => {
  if (!choice) {
    return null;
  }
  switch (resolveLocale()) {
    case 'fr':
      return choice.name_fr || choice.name_en || ERROR_MESSAGE;
    case 'de':
      return choice.name_de || choice.name_en || ERROR_MESSAGE;
    default:
      return choice.name_en || ERROR_MESSAGE;
  }
};
