import React from 'react';
import { translate } from 'react-admin';
import { selectLabel } from 'services/translations';
import { withStyles, createStyles } from '@material-ui/core';

const styles = createStyles({
  experienceCell: {
    width: '100%',
    textAlign: 'center',
  },
  experienceActivityArea: {
    fontWeight: 'bold',
    paddingTop: '2mm',
  },
});

const ActivityAreaLine = ({ classes, formatedExperiences }: any) => (
  <tr>
    {formatedExperiences.map((experience: any) => (
      <>
        <td />
        <td colSpan={1}>
          <div className={`${classes.experienceCell} ${classes.experienceActivityArea}`}>
            {selectLabel(experience.activityArea)}
          </div>
        </td>
      </>
    ))}
    <td />
  </tr>
);

export default translate(withStyles(styles)(ActivityAreaLine));
