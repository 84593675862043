import { resolveLocale } from 'services/translations';

export const SET_LANGUAGE = 'SET_LANGUAGE';

export default (previousState = resolveLocale(), { type, payload }: any) => {
  if (type === SET_LANGUAGE) {
    return payload.language;
  }
  return previousState;
};
