import React from 'react';
import { Experience } from '../types';
import { translate } from 'react-admin';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { colorPalette } from 'stylesheet';
import { selectLabel } from 'services/translations';
import DOMPurify from 'dompurify';

interface LeftColumnProps {
  experience: Experience;
}

const styles = createStyles({
  label: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    paddingRight: '3mm',
  },
  cell: {
    paddingBottom: '3mm',
  },
});

const rowStyles = createStyles({
  cell: {
    paddingBottom: '3mm',
  },
  label: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    paddingRight: '3mm',
  },
  bulletPoints: {
    '& li': {
      listStyle: 'disc',
      lineHeight: 1.1,
    },
    '& ul': {
      paddingLeft: '1rem',
    },
  },
});
const blockStyle = createStyles({
  block: {
    border: `solid ${colorPalette.lightText} 0.1mm`,
    marginRight: '1mm',
    padding: '1mm',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '2rem',
    flexGrow: 0,
  },
});
const blockContainerStyle = createStyles({
  blockContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
  },
});
const categoriesStyles = createStyles({
  categoriesList: {
    ...blockContainerStyle.blockContainer,
  },
  category: {
    ...blockStyle.block,
    flexBasis: '20%',
  },
});

const environmentsStyles = createStyles({
  categoriesList: {
    ...blockContainerStyle.blockContainer,
    flexWrap: 'wrap',
  },
  category: {
    ...blockStyle.block,
    flexBasis: '30%',
    marginBottom: '1mm',
  },
});

const TableRow = translate(
  withStyles(rowStyles)(({ classes, translate, value, label }: any) =>
    value ? (
      <tr>
        <td className={`${classes.label} ${classes.cell}`}>
          {translate(`consultantskillset.pdf.experiences.subtitle.${label}`)}
        </td>
        <td className={classes.cell}>{value}</td>
      </tr>
    ) : null,
  ),
);

const ProcurementCategoryList = withStyles(categoriesStyles)(({ classes, categories }: any) =>
  categories ? (
    <div className={classes.categoriesList}>
      {categories.map((category: string) => (
        <div key={category} className={classes.category}>
          {category}
        </div>
      ))}
    </div>
  ) : null,
);

const TechnicalEnvironmentList = withStyles(environmentsStyles)(({ classes, environments }: any) =>
  environments ? (
    <div className={classes.categoriesList}>
      {environments.map((category: string) => (
        <div key={category} className={classes.category}>
          {category}
        </div>
      ))}
    </div>
  ) : null,
);

const TableRowBulletPoint = translate(
  withStyles(rowStyles)(({ classes, translate, value, label }: any) =>
    value ? (
      <tr>
        <td className={`${classes.label} ${classes.cell}`}>
          {translate(`consultantskillset.pdf.experiences.subtitle.${label}`)}
        </td>
        <td className={classes.cell}>
          <div
            className={classes.bulletPoints}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
          />
        </td>
      </tr>
    ) : null,
  ),
);

const TableRowLastTitle = translate(
  withStyles(rowStyles)(({ classes, translate, value, label }: any) =>
    value ? (
      <tr>
        <td className={`${classes.label} ${classes.cell}`}>
          {translate(`consultantskillset.pdf.experiences.subtitle.${label}`)}
        </td>
        <td />
      </tr>
    ) : null,
  ),
);

const ExperienceLeftColumn = ({ experience }: LeftColumnProps) => {
  const procurement_processes =
    experience.procurement_processes &&
    experience.procurement_processes.length > 0 &&
    experience.procurement_processes.map(selectLabel).join(' - ');
  const procurement_categories =
    experience.procurement_categories &&
    experience.procurement_categories.length > 0 &&
    experience.procurement_categories.map(selectLabel);
  const activity_areas =
    experience.activityAreas &&
    experience.activityAreas.length > 0 &&
    experience.activityAreas.map(selectLabel).join(' - ');

  const technical_environments =
    experience.technicalEnvironments &&
    experience.technicalEnvironments.length > 0 &&
    experience.technicalEnvironments.map(selectLabel);

  return (
    <>
      <table>
        <tbody>
          <TableRow
            label="activityArea"
            value={activity_areas || selectLabel(experience.activityArea)}
          />
          <TableRow label="mission" value={experience.mission} />
          <TableRowBulletPoint label="missions" value={experience.missions} />
          <TableRow label="stakes" value={experience.stakes} />
          <TableRow label="place" value={experience.place} />
          <TableRow label="procurement_processes" value={procurement_processes} />
          <TableRow label="perimeter" value={selectLabel(experience.perimeter)} />
          <TableRowBulletPoint label="objectives" value={experience.objectives} />

          <TableRowLastTitle label="procurement_categories" value={procurement_categories} />
          <TableRowLastTitle label="technical_environments" value={technical_environments} />
        </tbody>
      </table>
      <ProcurementCategoryList categories={procurement_categories} />
      <TechnicalEnvironmentList environments={technical_environments} />
    </>
  );
};

export default translate(withStyles(styles)(ExperienceLeftColumn));
