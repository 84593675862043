import { Subsidiary } from 'services/subsidiaries';
import { selectLabel } from 'services/translations';
import { OTHER_SKILL_SOURCE } from 'pages/ConsultantSkillSet/Tabs/AdditionalInformationsTab';

const chooseListTranslation = (list: any[], labels: string[]) =>
  list.map((item: any) => {
    labels.forEach(label => {
      item[label] = selectLabel({
        name_en: item[`${label}_en`],
        name_fr: item[`${label}_fr`],
        name_de: item[`${label}_de`],
      });
    });
    return item;
  });

const chooseItemTranslation = (item: any, labels: string[]) => {
  labels.forEach(label => {
    item[label] = selectLabel({
      name_en: item[`${label}_en`],
      name_fr: item[`${label}_fr`],
      name_de: item[`${label}_de`],
    });
  });
  return item;
};

export const fillConsultantSkillSet = (
  consultantSkillSet: any,
  otherDatas: any,
  subsidiary: string,
) => {
  // Common fields to get from translation
  consultantSkillSet = chooseItemTranslation(consultantSkillSet, [OTHER_SKILL_SOURCE[subsidiary]]);

  switch (subsidiary) {
    case Subsidiary.BUYING_PEERS: {
      const {
        procurementCategoriesData,
        procurementProcessesData,
        procurementLeversData,
      } = otherDatas;

      consultantSkillSet.experiences = consultantSkillSet.experiences.map((experience: any) => {
        experience.procurement_categories = experience.procurement_categories.map((id: string) =>
          procurementCategoriesData.find((item: any) => item.id === id),
        );
        experience.procurement_processes = experience.procurement_processes.map((id: string) =>
          procurementProcessesData.find((item: any) => item.id === id),
        );
        experience.procurement_levers = experience.procurement_levers.map((id: string) =>
          procurementLeversData.find((item: any) => item.id === id),
        );
        return experience;
      });
      consultantSkillSet.experiences = chooseListTranslation(consultantSkillSet.experiences, [
        'mission',
        'stakes',
      ]);

      return consultantSkillSet;
    }
    case Subsidiary.FINANCE_PEERS: {
      const { activityAreasData } = otherDatas;
      consultantSkillSet.experiences = consultantSkillSet.experiences.map((experience: any) => {
        experience.activityAreas = experience.activityAreas.map((id: string) =>
          activityAreasData.find((item: any) => item.id === id),
        );
        return experience;
      });

      consultantSkillSet.experiences = chooseListTranslation(consultantSkillSet.experiences, [
        'missions',
        'objectives',
      ]);

      return consultantSkillSet;
    }
    case Subsidiary.SELLING_PEERS: {
      consultantSkillSet.experiences = chooseListTranslation(consultantSkillSet.experiences, [
        'missions',
        'objectives',
      ]);

      return consultantSkillSet;
    }
    case Subsidiary.SUPPLY_PEERS: {
      consultantSkillSet.experiences = chooseListTranslation(consultantSkillSet.experiences, [
        'missions',
        'objectives',
        'result',
      ]);

      return consultantSkillSet;
    }
    case Subsidiary.QUALITY_PEERS: {
      const { technicalEnvironmentsData, competenciesData, activityAreasData } = otherDatas;
      consultantSkillSet.experiences = consultantSkillSet.experiences.map((experience: any) => {
        experience.technicalEnvironments = experience.technicalEnvironments.map((id: string) =>
          technicalEnvironmentsData.find((item: any) => item.id === id),
        );
        return experience;
      });
      consultantSkillSet.competency_levels = consultantSkillSet.competency_levels.map(
        (competency_level: any) => {
          competency_level.activity_areas = competency_level.activity_areas.map((id: string) =>
            activityAreasData.find((item: any) => item.id === id),
          );
          competency_level.competency = competenciesData.find(
            (item: any) => item.id === competency_level.competency,
          );
          return competency_level;
        },
      );

      consultantSkillSet = chooseItemTranslation(consultantSkillSet, [
        'methods_and_tools',
        'standards',
      ]);
      consultantSkillSet.experiences = chooseListTranslation(consultantSkillSet.experiences, [
        'mission',
        'stakes',
      ]);

      return consultantSkillSet;
    }
    default:
      return consultantSkillSet;
  }
};
