import React from 'react';
import BlockTitle from 'components/BlockTitle';
import { withStyles, createStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import DOMPurify from 'dompurify';

const styles = createStyles({
  container: {
    height: '32mm',
    display: 'flex',
    flexDirection: 'row',
  },
});

const columnStyle = createStyles({
  columnContainer: {
    height: '100%',
    flex: '1 0 0',
    paddingLeft: '2mm',
  },
  bulletPoints: {
    marginTop: '2mm',
    '& li::before': {
      content: '"> "',
    },
    '& li': {
      marginBottom: '1mm',
    },
  },
});

const QualificationColumn = translate(
  withStyles(columnStyle)(
    ({ value, titleKey, translate, classes }: any) =>
      value && (
        <div className={classes.columnContainer}>
          <BlockTitle title={translate(`consultantskillset.pdf.qualifications.${titleKey}`)} />
          <div
            className={classes.bulletPoints}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}
          />
        </div>
      ),
  ),
);
const QualityQualifications = ({
  classes,
  methods_and_tools,
  standards,
}: {
  classes: any;
  methods_and_tools?: string;
  standards?: string;
}) => {
  return (
    <div className={classes.container}>
      {standards && <QualificationColumn value={standards} titleKey="standards" />}
      {methods_and_tools && (
        <QualificationColumn value={methods_and_tools} titleKey="methods_and_tools" />
      )}
    </div>
  );
};

export default withStyles(styles)(QualityQualifications);
