const cardStyles: any = {
  card: {
    width: 300,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top',
  },

  title: {
    fontSize: '1.5rem',
  },

  subtitle: {
    fontSize: '1rem',
  },

  container: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: '1rem 0',
  },

  field: {
    marginRight: '1rem',
  },

  actionButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default cardStyles;
