import React from 'react';
import { FormTab, ReferenceManyField, translate } from 'react-admin';
import ExperienceCards from 'pages/Experience/ExperienceCards';

const ExperiencesTab = ({ translate, index, isSmall, ...props }: any) => (
  <FormTab
    label={translate(`consultantskillset.tabs${isSmall ? '.small' : ''}.experiences`, { index })}
    {...props}
  >
    <ReferenceManyField reference="experiences" target="consultant_skill_set" addLabel={false}>
      <ExperienceCards consultantskillsetId={props.record && props.record.id} />
    </ReferenceManyField>
  </FormTab>
);

export default translate(ExperiencesTab);
