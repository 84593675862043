import React from 'react';
import { FullPageForm } from 'components/CustomLogin';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPassword = (props: any) => (
  <FullPageForm {...props}>
    <ResetPasswordForm params={props.match && props.match.params} />
  </FullPageForm>
);

export default ResetPassword;
