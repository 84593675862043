import React from 'react';
import { LoginForm, Notification, translate } from 'react-admin';
import { Card, MuiThemeProvider, withStyles, createStyles, Typography } from '@material-ui/core';
import { getUrlParameter } from 'services/urls';
import { getCustomTheme } from 'stylesheet';
import { getSimpleLogo } from 'services/logoProvider';
import { Link } from 'react-router-dom';

const styles = (theme: any) =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
    card: {
      width: 300,
      marginTop: '6em',
    },
    logo: {
      width: '6.25rem',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    message: {
      margin: '1em',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
  });

const styleForgottenPassword = createStyles({
  link: {
    textAlign: 'center',
    margin: '2em 0 1em 0',
  },
});
export const FullPageForm = translate(
  withStyles(styles)(({ children, classes, theme, translate, ...rest }: any) => (
    <MuiThemeProvider theme={theme || getCustomTheme()}>
      <div className={classes.main} {...rest}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <img className={classes.logo} src={getSimpleLogo()} alt="Logo" />
          </div>
          {getUrlParameter('signup') === '1' && (
            <Typography className={classes.message}>{translate('signup.confirmation')}</Typography>
          )}
          {children}
        </Card>
        <Notification />
      </div>
    </MuiThemeProvider>
  )),
);

const CustomLogin = ({ push, translate, classes, ...props }: any) => (
  <FullPageForm {...props}>
    <>
      <LoginForm />
      <Link to="/request-password">
        <Typography className={classes.link}>{translate('signup.forgottenPassword')}</Typography>
      </Link>
    </>
  </FullPageForm>
);

export default translate(withStyles(styleForgottenPassword)(CustomLogin));
