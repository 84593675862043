import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Header from './Header';
import Footer from './Footer';
import FirstPage from './FirstPage';
import DetailedExperience from './DetailedExperience';
import Timeline from './Timeline';
import Portfolio from './Portfolio';
import InterventionDomains from './InterventionDomains';
import MainSkills from './MainSkills';
import AdditionalInformations from './AdditionalInformations';
import QualityTable from './QualityTable';
import QualityQualifications from './QualityQualifications';
import { pageStyle, getCustomTheme } from 'stylesheet';
import { modelizeConsultantSkillSet } from './modelizer';
import { Subsidiary, getSubsidiary } from 'services/subsidiaries';
import SecondaryFormations from './SecondaryFormations';
import { Experience } from './types';
import { selectLabel } from 'services/translations';

const styles = createStyles({
  page: {
    ...pageStyle,
    pageBreakAfter: 'always',
    padding: '5mm',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  company: {
    margin: '1cm',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    maxHeight: '1.5cm',
    marginLeft: '1cm',
  },
  timeline: {
    height: '58mm',
    display: 'flex',
    flexDirection: 'column',
  },
  underTimelineContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '115mm',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '181mm',
  },
  leftColumn: {
    width: '150mm',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

const SHOW_SECONDARY_FORMATION: { [key: string]: boolean } = {
  [Subsidiary.BUYING_PEERS]: false,
  [Subsidiary.SELLING_PEERS]: true,
  [Subsidiary.SUPPLY_PEERS]: true,
  [Subsidiary.FINANCE_PEERS]: true,
  [Subsidiary.QUALITY_PEERS]: false,
};
const ConsultantSkillSetPdf = ({ classes, match, dataProvider }: any) => {
  if (match.params.subsidiary) {
    localStorage.setItem('ownSubsidiary', match.params.subsidiary);
  }
  const [data, setData] = useState();
  useEffect(
    () => {
      modelizeConsultantSkillSet(dataProvider, match.params.id).then(modelizedConsultantSkillSet =>
        setData(modelizedConsultantSkillSet),
      );
    },
    [dataProvider, match.params.id],
  );

  if (!data) {
    return <p data-pdf-download-loading>Loading ...</p>;
  }

  if (data === 'Error') {
    return <p>CV not found</p>;
  }

  const subsidiary = getSubsidiary();
  const detailedExperiences = data.experiences
    ? data.experiences.filter((experience: Experience) => experience.should_detail_be_displayed)
    : [];
  return (
    <MuiThemeProvider theme={getCustomTheme()}>
      <FirstPage consultantSkillSet={data} />
      <div className={classes.page}>
        <Header consultantSkillSet={data} />
        <div className={classes.container}>
          <div className={classes.timeline}>
            <Timeline
              experiences={data.experiences}
              jobTitle={selectLabel(data.job_title)}
              subsidiary={data.user.subsidiary}
            />
          </div>
          <div className={classes.underTimelineContainer}>
            <div className={classes.leftColumn}>
              {data.procurement_portfolio_handled && (
                <Portfolio portfolios={data.procurement_portfolio_handled} />
              )}
              {data.intervention_domain && (
                <InterventionDomains interventionDomains={data.intervention_domain} />
              )}
              {data.main_skills && <MainSkills mainSkills={data.main_skills} />}
              {data.competency_levels && <QualityTable competencyLevels={data.competency_levels} />}
              {SHOW_SECONDARY_FORMATION[subsidiary] && (
                <SecondaryFormations formations={data.formations} />
              )}
              {subsidiary === Subsidiary.QUALITY_PEERS && (
                <QualityQualifications
                  methods_and_tools={data.methods_and_tools}
                  standards={data.standards}
                />
              )}
            </div>
            <AdditionalInformations consultantSkillSet={data} subsidiary={subsidiary} />
          </div>
        </div>
        <Footer />
      </div>
      {detailedExperiences.map((_: any, index: number) =>
        index % 2 === 0 ? (
          <div className={classes.page} key={detailedExperiences[index].id}>
            <Header consultantSkillSet={data} />
            <DetailedExperience experience={detailedExperiences[index]} />
            <DetailedExperience experience={detailedExperiences[index + 1]} />
            <Footer />
          </div>
        ) : null,
      )}
    </MuiThemeProvider>
  );
};

export default withStyles(styles)(ConsultantSkillSetPdf);
