import React from 'react';
import {
  Datagrid,
  DeleteButton,
  FormTab,
  NumberField,
  ReferenceManyField,
  TextField,
  SelectField,
  translate,
} from 'react-admin';
import {
  EditInterventionDomainButton,
  AddNewInterventionDomainButton,
} from 'pages/InterventionDomain/Components';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { resolveLocale } from 'services/translations';
import { SUBSIDIARY_TO_LEVELS } from 'services/skillLevelAdministration';
import { Subsidiary, getSubsidiary } from 'services/subsidiaries';

const MAX_CATEGORIES: { [key: string]: number } = {
  [Subsidiary.SELLING_PEERS]: 6,
  [Subsidiary.SUPPLY_PEERS]: 6,
  [Subsidiary.FINANCE_PEERS]: 5,
};

const GET_REFERENCE: { [key: string]: string } = {
  [Subsidiary.SELLING_PEERS]: 'intervention-domains',
  [Subsidiary.SUPPLY_PEERS]: 'intervention-domains',
  [Subsidiary.FINANCE_PEERS]: 'main-skills',
};

const GET_SKILL_NAME: { [key: string]: string } = {
  [Subsidiary.SELLING_PEERS]: 'competency',
  [Subsidiary.SUPPLY_PEERS]: 'competency',
  [Subsidiary.FINANCE_PEERS]: 'skill',
};

const CONTAINS_YEARS_OF_EXPERIENCE: { [key: string]: boolean } = {
  [Subsidiary.SELLING_PEERS]: true,
  [Subsidiary.SUPPLY_PEERS]: true,
  [Subsidiary.FINANCE_PEERS]: false,
};

const GET_RECORD_ATTRIBUTE: { [key: string]: string } = {
  [Subsidiary.SELLING_PEERS]: 'intervention_domain',
  [Subsidiary.SUPPLY_PEERS]: 'intervention_domain',
  [Subsidiary.FINANCE_PEERS]: 'main_skills',
};

const styles = (theme: any) =>
  createStyles({
    addNewInterventionDomainButtonWrapper: {
      marginTop: 4 * theme.spacing.unit,
      marginBottom: 4 * theme.spacing.unit,
      display: 'flex',
      alignItems: 'center',
      width: 'auto',
    },
    addNewInterventionDomainButton: {
      marginRight: 2 * theme.spacing.unit,
    },
  });

const InterventionDomainsTab = ({ classes, translate, index, isSmall, ...props }: any) => {
  const subsidiaryName = getSubsidiary();

  const reference = GET_REFERENCE[subsidiaryName];
  const skillName = GET_SKILL_NAME[subsidiaryName];
  const constainsYearsOfExperience = CONTAINS_YEARS_OF_EXPERIENCE[subsidiaryName];
  const recordAttribut = GET_RECORD_ATTRIBUTE[subsidiaryName];

  return (
    <FormTab
      label={translate(`consultantskillset.tabs${isSmall ? '.small' : ''}.${reference}`, { index })}
      {...props}
    >
      <ReferenceManyField
        reference={`${reference}`}
        target="consultant_skill_set"
        addLabel={false}
        sort={{ field: `${skillName}__name_${resolveLocale()}`, order: 'DESC' }}
      >
        <Datagrid>
          <TextField
            source={`${skillName}.name_${resolveLocale()}`}
            label={translate(`resources.${reference}.fields.${skillName}.id`)}
            sortable={false}
          />
          <SelectField
            source="level"
            sortable={false}
            choices={SUBSIDIARY_TO_LEVELS[subsidiaryName].map(
              ({ id, translation_key }: { id: string; translation_key: string }) => ({
                id,
                name: translate(translation_key),
              }),
            )}
          />
          {constainsYearsOfExperience && (
            <NumberField source="years_of_experience" sortable={false} />
          )}
          <EditInterventionDomainButton
            consultantskillsetId={props.record && props.record.id}
            reference={reference}
          />
          <DeleteButton redirect={false} />
        </Datagrid>
      </ReferenceManyField>

      <div className={classes.addNewInterventionDomainButtonWrapper}>
        <AddNewInterventionDomainButton
          className={classes.addNewInterventionDomainButton}
          consultantskillsetId={props.record && props.record.id}
          reference={reference}
          disabled={
            props.record &&
            props.record[recordAttribut] &&
            props.record[recordAttribut].length >= MAX_CATEGORIES[subsidiaryName]
          }
        />
        {props.record &&
          props.record[recordAttribut] &&
          props.record[recordAttribut].length >= MAX_CATEGORIES[subsidiaryName] && (
            <Typography>
              {translate(`resources.${reference}.max_error`, {
                max_categories: MAX_CATEGORIES[subsidiaryName],
              })}
            </Typography>
          )}
      </div>
    </FormTab>
  );
};

export default withStyles(styles)(translate(InterventionDomainsTab));
