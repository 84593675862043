import React from 'react';
import {
  BooleanField,
  Button,
  DateField,
  DeleteButton,
  EditButton,
  Labeled,
  Link,
  TextField,
  translate,
  maxLength,
} from 'react-admin';
import { Card, CardContent, createStyles, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import cardStyles from 'components/Card';

const AddNewFormationButton = ({ consultantskillsetId }: any) => (
  <Button
    component={Link}
    to={{
      pathname: '/formations/create',
      search: `?consultantskillset=${consultantskillsetId}`,
    }}
    label="resources.formations.add"
  >
    <AddIcon />
  </Button>
);

const EditFormationButton = ({ consultantskillsetId, formationId }: any) => (
  <EditButton
    component={Link}
    to={{
      pathname: `/formations/${formationId}`,
      search: `?consultantskillset=${consultantskillsetId}`,
    }}
  />
);

const styles = createStyles(cardStyles);

const FormationCards = ({ ids, data, translate, classes, consultantskillsetId }: any) => (
  <>
    <div className={classes.container}>
      {ids &&
        ids.map((id: string) => {
          const record = data[id];
          return (
            <Card className={classes.card} key={id}>
              <CardContent>
                <TextField record={record} className={classes.title} source="school.name" />
                <TextField
                  record={record}
                  className={classes.subtitle}
                  source="formation_name"
                  validate={maxLength(255)}
                />
                <Labeled label={translate('resources.formations.fields.end_date')}>
                  <DateField record={record} source="end_date" options={{ year: 'numeric' }} />
                </Labeled>
                <Labeled label={translate('resources.formations.fields.is_main_formation')}>
                  <BooleanField record={record} source="is_main_formation" />
                </Labeled>
                <div className={classes.actionButtons}>
                  <EditFormationButton
                    formationId={id}
                    consultantskillsetId={consultantskillsetId}
                  />
                  <DeleteButton resource="formations" record={record} />
                </div>
              </CardContent>
            </Card>
          );
        })}
    </div>
    <AddNewFormationButton consultantskillsetId={consultantskillsetId} />
  </>
);

export default translate(withStyles(styles)(FormationCards));
