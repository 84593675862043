import React from 'react';
import {
  AutocompleteInput,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  translate,
} from 'react-admin';
import { parse } from 'query-string';
import { resolveLocale, selectLabel } from 'services/translations';

const PortfolioForm = translate(({ redirect, location, translate, ...props }: any) => {
  // Read the companyId from the creat_company form
  const { consultantskillset } = parse(location.search);
  if (consultantskillset) {
    props.record.consultantskillset = consultantskillset;
  }

  return (
    <SimpleForm {...props} redirect={redirect}>
      <ReferenceInput
        source="procurement_category.id"
        reference="procurement_categories"
        filterToQuery={(searchText: string) => ({ search: searchText })}
        sort={{ field: `name_${resolveLocale()}`, order: 'ASC' }}
        validate={required()}
      >
        <AutocompleteInput optionText={selectLabel} optionValue="id" />
      </ReferenceInput>

      <NumberInput
        source="tactical_buy"
        defaultValue={0}
        validate={[
          required(),
          minValue(0, translate('resources.procurement-portfolios-handled.negative_error')),
        ]}
      />

      <NumberInput
        source="project_purchasing"
        defaultValue={0}
        validate={[
          required(),
          minValue(0, translate('resources.procurement-portfolios-handled.negative_error')),
        ]}
      />

      <NumberInput
        source="category_management"
        defaultValue={0}
        validate={[
          required(),
          minValue(0, translate('resources.procurement-portfolios-handled.negative_error')),
        ]}
      />

      <NumberInput
        source="total_years_of_experience"
        defaultValue={0}
        validate={[
          required(),
          minValue(0, translate('resources.procurement-portfolios-handled.negative_error')),
        ]}
      />
    </SimpleForm>
  );
});
export default PortfolioForm;
