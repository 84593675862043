import ConsultantSkillSetList from './ConsultantSkillSetList';
import ConsultantSkillSetEdit from './ConsultantSkillSetEdit';
import PersonIcon from '@material-ui/icons/Person';

export default {
  name: 'consultantskillsets',
  icon: PersonIcon,
  list: ConsultantSkillSetList,
  edit: ConsultantSkillSetEdit,
};
