import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { translate } from 'react-admin';
import { ComputerSkill, ConsultantSkillSet, LanguageSkill } from './types';
import BlockTitle from 'components/BlockTitle';
import { colorPalette } from 'stylesheet';
import DOMPurify from 'dompurify';
import { selectLabel } from 'services/translations';
import { Subsidiary } from 'services/subsidiaries';
import { OTHER_SKILL_SOURCE } from 'pages/ConsultantSkillSet/Tabs/AdditionalInformationsTab';

const styles = ({ palette }: Theme) =>
  createStyles({
    additionalInfos: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '5mm',
      '& li::before': {
        content: '"> "',
      },
      '& li': {
        lineHeight: '16pt',
      },
    },
    procurementSkills: {
      height: '45mm',
    },
    languageAndComputerSkills: {
      height: '38mm',
    },
    languageAndComputerSkillsContainer: {
      display: 'flex',
      flexDirection: 'row',
      maxHeight: '15mm',
      padding: '2mm',
    },
    languageSkillsContainer: {
      flexGrow: 1,
    },
    computerSkillsContainer: {
      flexGrow: 2,
      '& ul': {
        columns: 2,
      },
    },
    hobbiesContainer: {
      border: `solid 0.5mm ${palette.secondary.dark}`,
      padding: '2mm',
    },
    procurementSkillsContainer: {
      padding: '2mm',
    },
    otherInformationsSubtitle: {
      marginBottom: '1mm',
      color: colorPalette.lightText,
      fontWeight: 'bold',
    },
  });

interface AdditionalInformationsProps {
  consultantSkillSet: ConsultantSkillSet;
  classes: any;
  translate: any;
  subsidiary: Subsidiary;
}

const compareLanguageSkills = (languageSkill1: LanguageSkill, languageSkill2: LanguageSkill) => {
  if (languageSkill1.level.order_index !== languageSkill2.level.order_index) {
    return languageSkill2.level.order_index - languageSkill1.level.order_index;
  }
  return selectLabel(languageSkill1.language) > selectLabel(languageSkill2.language) ? 1 : -1;
};

const AdditionalInformations = ({
  classes,
  translate,
  consultantSkillSet,
  subsidiary,
}: AdditionalInformationsProps) => {
  const first_name =
    consultantSkillSet.user.first_name.charAt(0).toUpperCase() +
    consultantSkillSet.user.first_name.slice(1).toLowerCase();

  const languageSkills = consultantSkillSet.language_skills
    ? consultantSkillSet.language_skills.sort(compareLanguageSkills).slice(0, 4)
    : [];

  return (
    <div className={classes.additionalInfos}>
      <div className={classes.procurementSkills}>
        <BlockTitle
          title={translate(
            `consultantskillset.pdf.additional_infos.${OTHER_SKILL_SOURCE[subsidiary]}`,
          )}
        />
        <div
          className={classes.procurementSkillsContainer}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(consultantSkillSet[
              OTHER_SKILL_SOURCE[subsidiary]
            ] as string),
          }}
        />
      </div>
      <div className={classes.languageAndComputerSkills}>
        <BlockTitle
          title={translate('consultantskillset.pdf.additional_infos.other_skills_title')}
        />
        <div className={classes.languageAndComputerSkillsContainer}>
          <div className={classes.languageSkillsContainer}>
            <div className={classes.otherInformationsSubtitle}>
              {translate('consultantskillset.pdf.additional_infos.language_skills')}
            </div>
            <div>
              <ul>
                {languageSkills.map((languageSkill: LanguageSkill) => (
                  <li key={languageSkill.language.name_fr}>{`${selectLabel(
                    languageSkill.language,
                  )} (${selectLabel(languageSkill.level)})`}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className={classes.computerSkillsContainer}>
            <div className={classes.otherInformationsSubtitle}>
              {translate('consultantskillset.pdf.additional_infos.computer_skills')}
            </div>
            <div>
              <ul>
                {consultantSkillSet.computer_skills.map((computerSkill: ComputerSkill) => (
                  <li key={computerSkill.name_fr}>{selectLabel(computerSkill)}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {consultantSkillSet.hobbies !== '<ul><li></li></ul>' && (
        <div>
          <BlockTitle
            title={translate('consultantskillset.pdf.additional_infos.hobbies_title', {
              first_name,
            })}
            noUnderline={true}
          />
          <div
            className={classes.hobbiesContainer}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(consultantSkillSet.hobbies) }}
          />
        </div>
      )}
    </div>
  );
};

export default translate(withStyles(styles)(AdditionalInformations));
