import React from 'react';
import { required, translate, ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { parseLongText, formatLongText } from 'services/dataProvider';
import { selectLabel, resolveLocale } from 'services/translations';
import { containerStyles } from 'components/customFormStyle';
import { withStyles } from '@material-ui/core';
import MultiLanguageTextInput from 'components/MultiLanguageTextInput';

const MAX_NUMBER_OF_OBJECTIVES = 5;

const MAX_NUMBER_OF_MISSIONS = 5;

export const validateFinancePeersExperience = (translate: any) => (values: any) => ({});

export const FinancePeersInputs = translate(
  withStyles(containerStyles)(({ translate, classes, ...props }: any) => (
    <div className={classes.container}>
      <ReferenceArrayInput
        {...props}
        source="activityAreas"
        reference="activity-area"
        sort={{ field: `name_${resolveLocale()}`, order: 'ASC' }}
      >
        <SelectArrayInput optionText={selectLabel} />
      </ReferenceArrayInput>

      <MultiLanguageTextInput
        isLongTextInput
        source="missions"
        rows={MAX_NUMBER_OF_MISSIONS}
        parse={parseLongText}
        format={formatLongText}
        validate={required()}
        placeholderKey="resources.experiences.placeholder.FinancePeers.missions"
        {...props}
      />
      <MultiLanguageTextInput
        isLongTextInput
        source="objectives"
        rows={MAX_NUMBER_OF_OBJECTIVES}
        parse={parseLongText}
        format={formatLongText}
        validate={required()}
        placeholderKey="resources.experiences.placeholder.FinancePeers.objectives"
        {...props}
      />
    </div>
  )),
);
