import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import { FormDataConsumer } from 'react-admin';
import { TextInput, LongTextInput, translate } from 'react-admin';
import { resolveLocale } from 'services/translations';

const languages: string[] = ['en', 'fr', 'de'];

// placeholders of all languages are all in en.json. The language is indicated by 2 letters at the end
const appendCurrentLanguage = (input: string, language: string) => `${input}_${language}`;

const MultiLanguageTextInput = translate(
  ({ source, placeholderKey, translate, isLongTextInput, ...props }: any) => {
    const [currentLanguage, setCurrentLanguage] = useState(resolveLocale());

    return (
      <FormDataConsumer>
        {({ formData, dispatch, ...rest }: any) => (
          <>
            {isLongTextInput ? (
              <LongTextInput
                source={appendCurrentLanguage(source, currentLanguage)}
                label={translate(`resources.${props.resource}.fields.${source}`)} //reconstruct react-admin translation scheme
                placeholder={translate(appendCurrentLanguage(placeholderKey, currentLanguage))}
                {...rest}
                {...props}
                className={''} // erase style from props
                fullWidth
              />
            ) : (
              <TextInput
                source={appendCurrentLanguage(source, currentLanguage)}
                label={translate(`resources.${props.resource}.fields.${source}`)} //reconstruct react-admin translation scheme
                placeholder={translate(appendCurrentLanguage(placeholderKey, currentLanguage))}
                {...rest}
                {...props}
              />
            )}

            <div>
              {languages.map(language => (
                <Button
                  size="small"
                  color="primary"
                  variant={language === currentLanguage ? 'contained' : 'text'}
                  onClick={() => setCurrentLanguage(language)}
                >
                  {language}
                </Button>
              ))}
            </div>
          </>
        )}
      </FormDataConsumer>
    );
  },
);

export default MultiLanguageTextInput;
