import React from 'react';
import { Layout, Sidebar } from 'react-admin';
import { isBusinessManager } from 'services/permissions';
import CustomAppBar from 'components/CustomAppBar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomLayout = ({ children, ...props }: any) => (
  <Layout
    {...props}
    appBar={CustomAppBar}
    // There is React Admin CSS rule setting the padding to 5px instead of 24px when there is a sidebar
    // So we need to offset it
    sidebar={isBusinessManager() ? Sidebar : () => <div style={{ marginRight: '19px' }} />}
  >
    {children}
    <ToastContainer />
  </Layout>
);

export default CustomLayout;
