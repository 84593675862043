import treePeersGroup from '../assets/tree_PeersGroup.png';
import logoPeersGroup from '../assets/logo_PeersGroup.png';

export const getSimpleLogo = () => {
  return treePeersGroup;
};

export const getCompleteLogo = () => {
  return logoPeersGroup;
};
