import React from 'react';
import { Button, Link, EditButton } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';

export const AddNewInterventionDomainButton = ({
  className,
  disabled,
  reference,
  consultantskillsetId,
}: any) => (
  <Button
    className={className}
    component={Link}
    disabled={disabled}
    to={{
      pathname: `/${reference}/create`,
      search: `?consultantskillset=${consultantskillsetId}`,
    }}
    label={`resources.${reference}.add`}
  >
    <AddIcon />
  </Button>
);

export const EditInterventionDomainButton = ({
  consultantskillsetId,
  reference,
  ...props
}: any) => {
  return (
    <EditButton
      component={Link}
      to={{
        pathname: `/${reference}/${props.record.id}`,
        search: `?consultantskillset=${consultantskillsetId}`,
      }}
    />
  );
};
