import React from 'react';
import {
  AutocompleteInput,
  minValue,
  NumberInput,
  ReferenceInput,
  required,
  SimpleForm,
  RadioButtonGroupInput,
  translate,
} from 'react-admin';
import { parse } from 'query-string';
import { resolveLocale, selectLabel } from 'services/translations';
import { SUBSIDIARY_TO_LEVELS } from 'services/skillLevelAdministration';
import { getSubsidiary } from 'services/subsidiaries';

const InterventionDomainForm = translate(({ redirect, location, translate, ...props }: any) => {
  // Read the companyId from the creat_company form
  const { consultantskillset } = parse(location.search);
  if (consultantskillset) {
    props.record.consultantskillset = consultantskillset;
  }

  const subsidiary = getSubsidiary();
  return (
    <SimpleForm {...props} redirect={redirect}>
      <ReferenceInput
        source="competency.id"
        reference="competencies"
        filterToQuery={(searchText: string) => ({ search: searchText })}
        sort={{ field: `name_${resolveLocale()}`, order: 'ASC' }}
        validate={required()}
      >
        <AutocompleteInput optionText={selectLabel} optionValue="id" />
      </ReferenceInput>

      <NumberInput
        source="years_of_experience"
        defaultValue={0}
        validate={[minValue(0, translate('resources.intervention-domains.negative_error'))]}
      />

      <RadioButtonGroupInput
        source="level"
        choices={SUBSIDIARY_TO_LEVELS[subsidiary].map(
          ({ id, translation_key }: { id: string; translation_key: string }) => ({
            id,
            name: translate(translation_key),
          }),
        )}
        validate={required()}
      />
    </SimpleForm>
  );
});
export default InterventionDomainForm;
