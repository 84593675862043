import React from 'react';
import { withStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import { CompetencyLevel } from '../types';
import BlockTitle from 'components/BlockTitle';
import { selectLabel } from 'services/translations';
import check from '../../../assets/check-24px.svg';
import { styles } from './styles';

interface QualityTableProps {
  competencyLevels: CompetencyLevel[];
  classes: any;
  translate: any;
}

interface Competency {
  title: string;
  years_of_experience: string;
  data: boolean[];
}
interface Table {
  activityAreas: string[];
  competencies: Competency[];
}

const getTable = (competencyLevels: CompetencyLevel[]) => {
  const activityAreaToCompetency: { [key: string]: string[] } = {};
  const activityAreaIdToName: { [key: string]: string } = {};

  //fill dictionaries
  competencyLevels.forEach(competencyLevel => {
    competencyLevel.activity_areas.forEach(activity_area => {
      if (activityAreaToCompetency[activity_area.id]) {
        activityAreaToCompetency[activity_area.id].push(competencyLevel.id);
      } else {
        activityAreaToCompetency[activity_area.id] = [competencyLevel.id];
        activityAreaIdToName[activity_area.id] = selectLabel(activity_area);
      }
    });
  });
  //select 3 most used activity area
  const selectedActivityAreas = Object.keys(activityAreaToCompetency)
    .sort((activityAreaId1, activityAreaId2) =>
      activityAreaIdToName[activityAreaId1] > activityAreaIdToName[activityAreaId2] ? 1 : -1,
    )
    .sort(
      (activityAreaId1, activityAreaId2) =>
        activityAreaToCompetency[activityAreaId2].length -
        activityAreaToCompetency[activityAreaId1].length,
    )
    .slice(0, 3);

  // compute and order competencies to display
  const selectedCompetencyLevels = competencyLevels
    .map(competencyLevel => {
      // for each competency get only data to display
      return {
        title: selectLabel(competencyLevel.competency),
        years_of_experience: competencyLevel.years_of_experience,
        displayedActivityAreasId: competencyLevel.activity_areas
          .map(activity_area => activity_area.id)
          .filter(id => selectedActivityAreas.includes(id)),
      };
    })
    .filter(element => element.displayedActivityAreasId.length > 0) // remove competencies which does not have displayed activity area
    .sort(
      (element1, element2) =>
        element2.displayedActivityAreasId.length - element1.displayedActivityAreasId.length,
    ) // sort competencies by decreasing number of activity area checked
    .slice(0, 6);

  const table = {
    activityAreas: selectedActivityAreas.map(id => activityAreaIdToName[id]),
    competencies: selectedCompetencyLevels.map(
      ({ years_of_experience, title, displayedActivityAreasId }) => ({
        title,
        years_of_experience,
        data: selectedActivityAreas.map(id => displayedActivityAreasId.includes(id)),
      }),
    ),
  };
  return table;
};

// Components
const Rows = translate(
  ({
    competencies,
    classes,
    translate,
  }: {
    competencies: Competency[];
    classes: any;
    translate: any;
  }) =>
    competencies.map(competency => (
      <tr className={classes.dataRow}>
        <td className={classes.firstEmptyCol} />
        <td className={`${classes.dataUnderline} ${classes.bold}`}>{competency.title}</td>
        <td className={`${classes.emptyCol} ${classes.dataUnderline}`} />
        {competency.data.map(isSelected => (
          <>
            <td
              className={`${classes.toCenter} ${classes.dataUnderline} ${
                classes.checkImageContainer
              } ${classes.notEmptyCol}`}
            >
              {isSelected && <img src={check} className={classes.checkImage} alt="" />}
            </td>
            <td className={`${classes.dataUnderline} ${classes.emptyCol}`} />
          </>
        ))}
        <td className={`${classes.dataUnderline} ${classes.bold}`}>
          {parseFloat(competency.years_of_experience) > 0
            ? translate('consultantskillset.pdf.qualityTable.years', {
                smart_count: parseFloat(competency.years_of_experience),
              })
            : null}
        </td>
        <td className={classes.lastEmptyCol} />
      </tr>
    )),
);

const QualityTable = ({ classes, competencyLevels, translate }: QualityTableProps) => {
  if (!competencyLevels) {
    return null;
  }
  const table: Table = getTable(competencyLevels);
  return (
    <>
      <BlockTitle title={translate('consultantskillset.pdf.qualityTable.title')} />
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td colSpan={2} className={classes.headerUnderline}>
                {translate('consultantskillset.pdf.main-skills.skill')}
              </td>
              <td />
              {table.activityAreas.map(activityAreaName => (
                <>
                  <td className={`${classes.toCenter} ${classes.headerUnderline}`}>
                    {activityAreaName}
                  </td>
                  <td />
                </>
              ))}
              <td colSpan={2} className={classes.headerUnderline} />
            </tr>
            <Rows competencies={table.competencies} classes={classes} />
          </tbody>
        </table>
      </div>
    </>
  );
};

export default translate(withStyles(styles)(QualityTable));
