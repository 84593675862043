import React from 'react';
import { Link } from 'react-router-dom';
import {
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  TextField,
  TextInput,
  translate,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { getSimpleLogo } from 'services/logoProvider';
import SelectExportLanguage from './SelectExportLanguage';
import DownloadButton from './DownloadButton';
import { Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Subsidiary, getSubsidiary } from 'services/subsidiaries';
import { selectLabel } from 'services/translations';

const styles = (theme: any) =>
  createStyles({
    logoCell: {
      textAlign: 'center',
      width: '100px',
    },
    logo: {
      width: '45px',
    },
    icon: {
      marginRight: theme.spacing.unit,
    },
  });

const SUBSIDARY_FILTERS: { [key: string]: JSX.Element[] } = {
  [Subsidiary.BUYING_PEERS]: [
    <ReferenceInput
      label="resources.consultantskillsets.search_procurement_categories"
      source="procurement_portfolio_handled__procurement_category__id"
      reference="procurement_categories"
      alwaysOn
    >
      <SelectInput optionText={selectLabel} resettable />
    </ReferenceInput>,
  ],
  [Subsidiary.SELLING_PEERS]: [
    <ReferenceInput
      label="resources.consultantskillsets.search_competency"
      source="intervention_domain__competency__id"
      reference="competencies"
      alwaysOn
    >
      <SelectInput optionText={selectLabel} resettable />
    </ReferenceInput>,
    <ReferenceInput
      label="resources.consultantskillsets.search_activityArea"
      source="experiences__activityArea__id"
      reference="activity-area"
      alwaysOn
    >
      <SelectInput optionText={selectLabel} resettable />
    </ReferenceInput>,
  ],
  [Subsidiary.SUPPLY_PEERS]: [
    <ReferenceInput
      label="resources.consultantskillsets.search_competency"
      source="intervention_domain__competency__id"
      reference="competencies"
      alwaysOn
    >
      <SelectInput optionText={selectLabel} resettable />
    </ReferenceInput>,
  ],
  [Subsidiary.FINANCE_PEERS]: [
    <ReferenceInput
      label="resources.consultantskillsets.search_skill"
      source="main_skills__skill__id"
      reference="skills"
      alwaysOn
    >
      <SelectInput optionText={selectLabel} resettable />
    </ReferenceInput>,
  ],
  [Subsidiary.QUALITY_PEERS]: [
    <ReferenceInput
      label="resources.consultantskillsets.search_competency"
      source="competency_levels__competency__id"
      reference="competencies"
      alwaysOn
    >
      <SelectInput optionText={selectLabel} resettable />
    </ReferenceInput>,
  ],
};

// Add here the config for selectors
const Filters = ({ subsidiary, ...props }: any) => {
  return (
    <Filter {...props}>
      <TextInput label="resources.consultantskillsets.search" source="search" alwaysOn />
      {SUBSIDARY_FILTERS[subsidiary]}
    </Filter>
  );
};

const PreviewButton = translate((props: any) => (
  <Link
    style={{ textDecoration: 'none' }}
    to={`${props.basePath}/${props.record.id}/render`}
    target="_blank"
  >
    <Button color="primary" size="small">
      <VisibilityIcon className={props.classes.icon} />
      {props.translate('layout.preview')}
    </Button>
  </Link>
));

const ConsultantSkillSetList = ({ classes, ...props }: any) => {
  const subsidiary = getSubsidiary();

  return (
    <List
      {...props}
      actions={<SelectExportLanguage />}
      filters={<Filters subsidiary={subsidiary} />}
      bulkActionButtons={false}
    >
      <Datagrid>
        <FunctionField
          source="user.is_candidate"
          cellClassName={classes.logoCell}
          headerClassName={classes.logoCell}
          render={(record: any) =>
            record.user.is_candidate ? null : (
              <img src={getSimpleLogo()} alt="Logo" className={classes.logo} />
            )
          }
          sortable={false}
        />
        <TextField source="user.first_name" sortable={false} />
        <TextField source="user.last_name" sortable={false} />
        <TextField source="user.email" sortable={false} />
        <EditButton />
        <PreviewButton classes={classes} />
        <DownloadButton />
      </Datagrid>
    </List>
  );
};

export default withStyles(styles)(ConsultantSkillSetList);
