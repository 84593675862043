import React from 'react';
import { colorPalette } from 'stylesheet';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';

const styles = ({ palette }: Theme) =>
  createStyles({
    container: {
      paddingBottom: '2mm',
      width: '100%',
      fontSize: '16pt',
      color: colorPalette.darkText,
    },
    underline: {
      borderBottom: `solid 0.5mm ${palette.primary.dark}`,
    },
  });

const BlockTitle = withStyles(styles)(({ classes, title, noUnderline }: any) => (
  <div className={`${classes.container} ${noUnderline ? '' : classes.underline}`}>{title}</div>
));

export default BlockTitle;
