import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { translate } from 'react-admin';
import { colorPalette } from 'stylesheet';
import { MainSkill } from './types';
import BlockTitle from 'components/BlockTitle';
import { selectLabel } from 'services/translations';
import { SUBSIDIARY_TO_LEVELS } from 'services/skillLevelAdministration';
import check from '../../assets/check-24px.svg';
import { getSubsidiary } from 'services/subsidiaries';

interface MainSkillProps {
  mainSkills: MainSkill[];
  classes: any;
  translate: any;
}
const styles = ({ palette }: Theme) =>
  createStyles({
    tableContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      marginTop: '1cm',
    },
    table: {
      width: '100%',
      '& td': {
        verticalAlign: 'bottom',
      },
    },
    firstEmptyCol: {
      width: '2mm',
    },
    emptyCol: {
      width: '2mm',
    },
    lastEmptyCol: {
      width: '5mm',
    },
    headerUnderline: {
      borderBottom: `solid ${palette.primary.main} 1mm`,
      paddingBottom: '2mm',
      fontSize: '11pt',
    },
    secondHeader: {
      '& $headerUnderline': {
        paddingTop: '6mm',
      },
    },
    dataRow: {
      '& td': {
        padding: '4mm 0 2mm 0',
        whiteSpace: 'nowrap',
      },
    },
    dataUnderline: {
      borderBottom: `solid ${colorPalette.lightText} 0.5mm`,
    },
    bold: {
      fontWeight: 'bold',
      color: palette.primary.dark,
    },
    toCenter: {
      textAlign: 'center',
    },
    checkImageContainer: {
      position: 'relative',
    },
    checkImage: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    notEmptyCol: {
      width: '20%',
    },
  });

const Rows = ({ data, classes, subsidiary }: any) =>
  data.map((mainSkill: MainSkill) => (
    <tr className={classes.dataRow}>
      <td className={classes.firstEmptyCol} />
      <td className={`${classes.dataUnderline} ${classes.bold}`}>{selectLabel(mainSkill.skill)}</td>
      <td className={`${classes.emptyCol} ${classes.dataUnderline}`} />
      {SUBSIDIARY_TO_LEVELS[subsidiary].map(({ id }: { id: string }, index: number) => (
        <>
          <td
            className={`${classes.toCenter} ${classes.dataUnderline} ${
              classes.checkImageContainer
            } ${classes.notEmptyCol}`}
          >
            {id === mainSkill.level && <img src={check} className={classes.checkImage} alt="" />}
          </td>
          <td className={`${classes.dataUnderline} ${classes.emptyCol}`} />
        </>
      ))}
      <td className={classes.lastEmptyCol} />
    </tr>
  ));

const MainSkillsComponent = ({ classes, mainSkills, translate }: MainSkillProps) => {
  if (!mainSkills) {
    return null;
  }

  const subsidiary = getSubsidiary();
  return (
    <>
      <BlockTitle title={translate('consultantskillset.pdf.main-skills.title')} />
      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td colSpan={2} className={classes.headerUnderline}>
                {translate('consultantskillset.pdf.main-skills.skill')}
              </td>
              <td />
              {SUBSIDIARY_TO_LEVELS[subsidiary].map(({ translation_key, is_sub_column }) => (
                <>
                  <td className={`${classes.toCenter} ${classes.headerUnderline}`}>
                    {translate(translation_key)}
                  </td>
                  <td />
                </>
              ))}
            </tr>

            <Rows data={mainSkills} classes={classes} subsidiary={subsidiary} />
          </tbody>
        </table>
      </div>
    </>
  );
};

export default translate(withStyles(styles)(MainSkillsComponent));
