import React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { ConsultantSkillSet } from './types';
import { translate } from 'react-admin';
import { getSimpleLogo } from 'services/logoProvider';
import { colorPalette } from 'stylesheet';
import { format } from 'date-fns';

const styles = ({ palette }: Theme) =>
  createStyles({
    container: {
      width: '100%',
      color: palette.primary.dark,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '8mm',
    },
    names: {
      fontWeight: 'bold',
      textTransform: 'capitalize',
    },
    firstLine: {
      fontSize: '20pt',
      marginBottom: '2mm',
    },
    secondLine: {
      fontSize: '14pt',
      color: colorPalette.darkText,
    },
    topLogo: {
      height: '100%',
    },
  });

interface HeaderProps {
  consultantSkillSet: ConsultantSkillSet;
  classes: any;
  translate: any;
}
const Header = ({ classes, consultantSkillSet, translate }: HeaderProps) => {
  const schools =
    consultantSkillSet.formations &&
    consultantSkillSet.formations
      .filter(formation => formation.is_main_formation && formation.school && formation.school.name)
      .sort((formation1, formation2) =>
        new Date(formation2.end_date) > new Date(formation1.end_date) ? 1 : -1,
      )
      .map(
        formation => `${formation.school.name} (${format(new Date(formation.end_date), 'yyyy')})`,
      );
  return (
    <div className={classes.container}>
      <div>
        <p className={classes.firstLine}>
          <span className={classes.names}>{`${
            consultantSkillSet.user.first_name
          } ${consultantSkillSet.user.last_name &&
            consultantSkillSet.user.last_name.charAt(0).toUpperCase()}. - `}</span>

          {consultantSkillSet.years_of_experience
            ? translate(
                'consultantskillset.pdf.header.years_of_experience',
                consultantSkillSet.years_of_experience,
              )
            : ''}
        </p>
        <p className={classes.secondLine}> {schools ? schools.join(' - ') : ''}</p>
      </div>
      <img src={getSimpleLogo()} className={classes.topLogo} alt="logo" />
    </div>
  );
};

export default translate(withStyles(styles)(Header));
