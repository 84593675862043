import { Subsidiary } from 'services/subsidiaries';

interface TranslationInformation {
  id: string;
  translation_key: string;
  is_sub_column: boolean;
}

// order of this list maters, it corresponds to the order of displayed columns
// Asked by SellingPeers and SupplyPeers
export const levelChoices5: TranslationInformation[] = [
  {
    id: 'Expert',
    translation_key: 'resources.intervention-domains.levels.expert',
    is_sub_column: false,
  },
  {
    id: 'ConfirmedExpert',
    translation_key: 'resources.intervention-domains.levels.confirmed-expert',
    is_sub_column: true,
  },
  {
    id: 'Confirmed',
    translation_key: 'resources.intervention-domains.levels.confirmed',
    is_sub_column: false,
  },
  {
    id: 'BeginnerConfirmed',
    translation_key: 'resources.intervention-domains.levels.beginner-confirmed',
    is_sub_column: true,
  },
  {
    id: 'Beginner',
    translation_key: 'resources.intervention-domains.levels.beginner',
    is_sub_column: false,
  },
];

// order of this list maters, it corresponds to the order of displayed columns
// Asked by FinancePeers
export const levelChoices3: TranslationInformation[] = [
  {
    id: 'Beginner',
    translation_key: 'resources.main-skills.levels.beginner',
    is_sub_column: false,
  },
  {
    id: 'Confirmed',
    translation_key: 'resources.main-skills.levels.confirmed',
    is_sub_column: false,
  },
  {
    id: 'Expert',
    translation_key: 'resources.main-skills.levels.expert',
    is_sub_column: false,
  },
];

export const SUBSIDIARY_TO_LEVELS: { [key: string]: TranslationInformation[] } = {
  [Subsidiary.SELLING_PEERS]: levelChoices5,
  [Subsidiary.SUPPLY_PEERS]: levelChoices5,
  [Subsidiary.FINANCE_PEERS]: levelChoices3,
};
