import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = createStyles({
  selector: {
    width: '100%',
  },
});

// see http://redux-form.com/6.4.3/examples/material-ui/
export const renderInput = ({
  meta: { touched, error } = { touched: false, error: '' },
  input,
  ...props
}: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...input}
    {...props}
    fullWidth
  />
);

// inspired from http://redux-form.com/6.4.3/examples/material-ui/
// but SelectField does not exist so I used https://material-ui.com/components/selects/
export const renderSelectField = withStyles(styles)(
  ({
    meta: { touched, error } = { touched: false, error: '' },
    input,
    label,
    children,
    classes,
    ...props
  }: any) => (
    <FormControl error={!!(touched && error)} className={classes.selector}>
      <InputLabel>{label}</InputLabel>
      <Select
        error={!!(touched && error)}
        {...input}
        onChange={(event: any) => {
          input.onChange(event.target.value);
        }}
        children={children}
        {...props}
      />
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  ),
);
