import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Field } from 'redux-form';
import { endOfMonth, format } from 'date-fns';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, withStyles } from '@material-ui/core';

const getDefaultDate = (views: ('day' | 'month' | 'year')[]) => {
  const startDate = new Date();
  // set default value to no selectable fields
  if (!views.includes('day')) {
    startDate.setDate(1);
  }
  if (!views.includes('month')) {
    startDate.setMonth(1);
  }
  if (!views.includes('year')) {
    startDate.setFullYear(1008); //année bissextile fantaisiste
  }
  return startDate;
};

const SelectDay = ({ input, touched, error, children, ...props }: any) => (
  <Select
    {...props}
    // For select field, we need to override input functions (cf redux-form doc)
    value={input.value && input.value.getDate()}
    onChange={(event: any) => {
      input.value.setDate(event.target.value);
      input.onChange(input.value);
    }}
    errorText={touched && error}
  >
    {// generates day numbers depending on the selected month
    input.value
      ? Object.keys([...Array(endOfMonth(input.value).getDate())]).map(
          (value: any, index: number) => (
            <MenuItem value={index + 1} key={value}>
              {index + 1}
            </MenuItem>
          ),
        )
      : null}
  </Select>
);

const SelectMonth = ({ input, touched, error, children, ...props }: any) => (
  <Select
    {...props}
    floatingLabelText="month"
    errorText={touched && error}
    value={input.value && input.value.getMonth()}
    onChange={(event: any) => {
      input.value.setMonth(event.target.value);
      input.onChange(input.value);
    }}
  >
    {Object.keys([...Array(12)]).map((value: any, index: any) => (
      <MenuItem value={index} key={value}>
        {format(
          new Date(2000, index, 1),
          'LLLL',
        ) /* use date-fns to display month name in the language of the navigator*/}
      </MenuItem>
    ))}
  </Select>
);

const currentYear = new Date().getFullYear();
const YEARS_BEFORE = 100;
const YEARS_AFTER = 20;
const getYearFromIndex = (index: number) => currentYear - YEARS_BEFORE + index;

const SelectYear = ({ input, touched, error, children, ...props }: any) => (
  <Select
    {...props}
    errorText={touched && error}
    value={input.value && input.value.getFullYear()}
    onChange={(event: any) => {
      input.value.setFullYear(event.target.value);
      input.onChange(input.value);
    }}
  >
    {Object.keys([...Array(YEARS_BEFORE + YEARS_AFTER + 1)]).map((value: any, index: number) => (
      <MenuItem value={getYearFromIndex(index)} key={value}>
        {getYearFromIndex(index)}
      </MenuItem>
    ))}
  </Select>
);

const styles = createStyles({
  displayFlex: {
    display: 'flex',
    flexDirection: 'row',
  },
});
/*
For documentations go on :
https://marmelab.com/react-admin/doc/2.9/Inputs.html
and
https://redux-form.com/6.5.0/examples/material-ui/
*/
const renderDateField = (views: ('day' | 'month' | 'year')[], disabled: boolean) =>
  withStyles(styles)(({ classes, input, meta: { touched, error } }: any) => {
    if (!input.value) {
      input.onChange(getDefaultDate(views));
    }

    return (
      <FormControl error={!!(touched && error)} className={classes.displayFlex} disabled={disabled}>
        {views.includes('day') && <SelectDay input={input} touched={touched} error={error} />}
        {views.includes('month') && <SelectMonth input={input} touched={touched} error={error} />}
        {views.includes('year') && <SelectYear input={input} touched={touched} error={error} />}
      </FormControl>
    );
  });

const DatePicker = ({ views, source, format, parse, validate, disabled }: any) => (
  <Field
    name={source}
    component={renderDateField(views, disabled)}
    format={format}
    parse={parse}
    validate={validate}
  />
);

export default DatePicker;
