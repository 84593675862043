import React, { Fragment } from 'react';
import { translate } from 'react-admin';
import { getCompleteLogo } from 'services/logoProvider';
import { withStyles, createStyles, Theme } from '@material-ui/core';

const styles = ({ palette }: Theme) =>
  createStyles({
    experienceCell: {
      width: '100%',
      textAlign: 'center',
    },
    notPeerConsultant: {
      borderBottom: `solid 2mm ${palette.primary.main}`,
    },
    peerConsultant: {
      borderBottom: `solid 2mm ${palette.secondary.main}`,
    },
    companyContainer: {
      paddingBottom: '2mm',
    },
    companyLogo: {
      height: '8mm',
    },
  });

const CompanyLine = ({ classes, formatedExperiences, subsidiary, showClient }: any) => (
  <tr>
    {formatedExperiences.map((experience: any) => (
      <Fragment key={experience.company.name}>
        <td />
        <td
          colSpan={1}
          className={`${classes.experienceCell} ${
            experience.as_internal_consultant ? classes.peerConsultant : classes.notPeerConsultant
          }`}
        >
          <div className={classes.companyContainer}>
            {experience.company ? (
              experience.company.logo ? (
                <img
                  src={
                    showClient(subsidiary, experience) ? experience.company.logo : getCompleteLogo()
                  }
                  alt="Company Logo"
                  className={classes.companyLogo}
                />
              ) : (
                <span>{experience.company.name}</span>
              )
            ) : null}
          </div>
        </td>
      </Fragment>
    ))}
    <td />
  </tr>
);

export default translate(withStyles(styles)(CompanyLine));
