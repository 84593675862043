import React, { Fragment } from 'react';
import { withStyles, createStyles } from '@material-ui/core';
import { getCompleteLogo } from 'services/logoProvider';
import { getSubsidiary } from 'services/subsidiaries';

const styles = createStyles({
  experienceCell: {
    width: '100%',
    textAlign: 'center',
  },
  bpLogo: {
    height: '8mm',
  },
  logoLine: {
    height: '9mm',
  },
});

const PeersGroupLogoLine = ({ classes, groupedExperiences, showLogos }: any) => {
  const subsidiary = getSubsidiary();
  return (
    <tr className={classes.logoLine}>
      {showLogos &&
        groupedExperiences.map((group: any) => (
          <Fragment key={group.start_date.toString()}>
            <td className={classes.experienceCell} />
            <td colSpan={group.nbOfCells} className={classes.experienceCell}>
              {group.as_internal_consultant ? (
                <img
                  src={getCompleteLogo()}
                  alt={`${subsidiary} Logo`}
                  className={classes.bpLogo}
                />
              ) : null}
            </td>
          </Fragment>
        ))}
      {groupedExperiences.length > 0 ? <td className={classes.experienceCell}> </td> : null}
    </tr>
  );
};

export default withStyles(styles)(PeersGroupLogoLine);
