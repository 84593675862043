import React from 'react';
import { AutocompleteInput, ReferenceInput, required, SimpleForm, translate } from 'react-admin';
import { parse } from 'query-string';
import { resolveLocale, selectLabel } from 'services/translations';

const LanguageSkillForm = translate(({ redirect, location, translate, ...props }: any) => {
  const { consultantskillset } = parse(location.search);
  if (consultantskillset) {
    props.record.consultantskillset = consultantskillset;
  }

  return (
    <SimpleForm {...props} redirect={redirect}>
      <ReferenceInput
        source="language.id"
        reference="languages"
        filterToQuery={(searchText: string) => ({ search: searchText })}
        sort={{ field: `name_${resolveLocale()}`, order: 'ASC' }}
        validate={required()}
      >
        <AutocompleteInput optionText={selectLabel} optionValue="id" />
      </ReferenceInput>

      <ReferenceInput
        source="level.id"
        reference="language-skill-levels"
        filterToQuery={(searchText: string) => ({ search: searchText })}
        sort={{ field: 'order_index', order: 'DESC' }}
        validate={required()}
      >
        <AutocompleteInput optionText={selectLabel} optionValue="id" />
      </ReferenceInput>
    </SimpleForm>
  );
});
export default LanguageSkillForm;
