import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API_URL } from 'services/dataProvider';
import TextField from '@material-ui/core/TextField';

interface FormData {
  email: string;
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: spacing.unit,
    },
  });

// see http://redux-form.com/6.4.3/examples/material-ui/
export const renderInput = ({
  meta: { touched, error } = { touched: false, error: '' },
  input,
  ...props
}: any) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...input}
    {...props}
    fullWidth
  />
);

const requestPassword = async (values: FormData, dispatch: any, props: any) => {
  const response = await fetch(`${API_URL}/auth/users/reset_password/`, {
    method: 'POST',
    headers: new Headers({
      'content-type': 'application/json',
    }),
    body: JSON.stringify({
      email: values.email,
    }),
  });

  if (response.status === 400) {
    const content = await response.json();
    throw new SubmissionError(content);
  }
};

const RequestPasswordForm = ({ classes, handleSubmit, submitting, translate, params }: any) => (
  <>
    <form onSubmit={handleSubmit}>
      <div className={classes.form}>
        <div className={classes.input}>
          <Field
            id="email"
            name="email"
            component={renderInput}
            label={translate('requestPassword.email')}
            type="email"
            disabled={submitting}
          />
        </div>
      </div>
      <CardActions>
        <Button
          variant="raised"
          type="submit"
          color="primary"
          disabled={submitting}
          className={classes.button}
        >
          {submitting && <CircularProgress className={classes.icon} size={18} thickness={2} />}
          {translate('requestPassword.submit')}
        </Button>
      </CardActions>
    </form>
  </>
);

const enhance = compose(
  withStyles(styles),
  connect(
    null,
    { push },
  ),
  reduxForm({
    form: 'requestPassword',
    validate: (values: FormData, props: any) => {
      const errors = {
        email: '',
      };
      const { translate } = props;

      if (!values.email) {
        errors.email = translate('ra.validation.required');
      }

      return errors;
    },
    onSubmit: requestPassword,
  }),
);

const EnhancedRequestPasswordForm = translate(enhance(RequestPasswordForm));

export default EnhancedRequestPasswordForm;
