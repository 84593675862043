import React from 'react';
import { Redirect } from 'react-router';
import { isBusinessManager } from 'services/permissions';

// This is the only way to redirect after login
// See https://stackoverflow.com/a/54422778
const InitialRedirect = () => {
  const businessManager = isBusinessManager();
  const ownConsultantSkillSet = localStorage.getItem('ownConsultantSkillSet');

  if (businessManager === null) {
    return null;
  }

  return businessManager ? (
    <Redirect to="/consultantskillsets" />
  ) : (
    <Redirect to={`/consultantskillsets/${ownConsultantSkillSet}`} />
  );
};

export default InitialRedirect;
