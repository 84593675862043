import React from 'react';
import {
  BooleanInput,
  required,
  maxLength,
  SimpleForm,
  TextInput,
  translate,
  Labeled,
} from 'react-admin';
import { parse } from 'query-string';
import { apiDateFormatter, apiDateParser } from 'services/date';
import { parseLongText, formatLongText } from 'services/dataProvider';
import { EndDateInput, CompanyInput } from './ExperienceCustomInputs';
import MultiLanguageTextInput from 'components/MultiLanguageTextInput';

import { validateBuyingPeersExperience, BuyingPeersInputs } from './BuyingPeersForm';
import { validateSellingPeersExperience, SellingPeersInputs } from './SellingPeersForm';
import { validateSupplyPeersExperience, SupplyPeersInputs } from './SupplyPeersForm';
import { validateFinancePeersExperience, FinancePeersInputs } from './FinancePeersForm';
import { validateQualityPeersExperience, QualityPeersInputs } from './QualityPeersForm';
import { Subsidiary, getSubsidiary } from 'services/subsidiaries';

import DatePicker from 'components/DatePicker';

const FORM_VALIDATOR: { [key: string]: Function } = {
  [Subsidiary.BUYING_PEERS]: validateBuyingPeersExperience,
  [Subsidiary.SELLING_PEERS]: validateSellingPeersExperience,
  [Subsidiary.SUPPLY_PEERS]: validateSupplyPeersExperience,
  [Subsidiary.FINANCE_PEERS]: validateFinancePeersExperience,
  [Subsidiary.QUALITY_PEERS]: validateQualityPeersExperience,
};
const SUBSIDIARY_FORMS: { [key: string]: Function } = {
  [Subsidiary.BUYING_PEERS]: BuyingPeersInputs,
  [Subsidiary.SELLING_PEERS]: SellingPeersInputs,
  [Subsidiary.SUPPLY_PEERS]: SupplyPeersInputs,
  [Subsidiary.FINANCE_PEERS]: FinancePeersInputs,
  [Subsidiary.QUALITY_PEERS]: QualityPeersInputs,
};

const IS_PLACE_REQUIRED: { [key: string]: boolean } = {
  [Subsidiary.BUYING_PEERS]: true,
  [Subsidiary.SELLING_PEERS]: true,
  [Subsidiary.SUPPLY_PEERS]: true,
  [Subsidiary.FINANCE_PEERS]: false,
  [Subsidiary.QUALITY_PEERS]: true,
};
const ExperienceForm = translate(
  ({ redirect, location, localeLanguage, translate, ...props }: any) => {
    // Read the companyId from the creat_company form
    const { consultantskillset, companyId } = parse(location.search);
    if (consultantskillset) {
      props.record.consultantskillset = consultantskillset;
    }
    if (companyId) {
      props.record.company = { id: companyId };
    }

    const experienceId = props.record.id;

    const subsidiaryName = getSubsidiary();

    const SubsidiaryForm = SUBSIDIARY_FORMS[subsidiaryName] || (() => null);
    return (
      <SimpleForm
        {...props}
        redirect={redirect}
        validate={FORM_VALIDATOR[subsidiaryName](translate)}
      >
        <CompanyInput consultantskillset={consultantskillset} experienceId={experienceId} />
        <MultiLanguageTextInput
          source="title"
          placeholderKey={`resources.experiences.placeholder.${subsidiaryName}.title`}
          validate={[required(), maxLength(255)]}
        />
        <Labeled label={translate('resources.experiences.fields.start_date')}>
          <DatePicker
            source="start_date"
            parse={apiDateParser}
            format={apiDateFormatter}
            validate={required()}
            views={['month', 'year']}
          />
        </Labeled>

        <EndDateInput />

        <BooleanInput
          source="as_internal_consultant"
          label={translate('resources.experiences.fields.as_internal_consultant', {
            subsidiaryName,
          })}
        />

        <BooleanInput
          source="should_detail_be_displayed"
          label={translate('resources.experiences.fields.should_detail_be_displayed', {
            subsidiaryName,
          })}
          defaultValue={true}
        />

        <TextInput
          source="place"
          placeholder={translate('resources.experiences.placeholder.place')}
          validate={[...(IS_PLACE_REQUIRED[subsidiaryName] ? [required()] : []), maxLength(255)]}
        />

        <SubsidiaryForm />

        <MultiLanguageTextInput
          isLongTextInput
          source="realisations"
          placeholderKey={`resources.experiences.placeholder.${subsidiaryName}.realisations`}
          rows="6"
          parse={parseLongText}
          format={formatLongText}
          validate={required()}
        />
      </SimpleForm>
    );
  },
);

export default ExperienceForm;
