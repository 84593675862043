import React from 'react';
import { translate } from 'react-admin';
import Button from '@material-ui/core/Button';
import { createStyles, withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
import { goBack, push } from 'react-router-redux';

const styles = (theme: any) =>
  createStyles({
    icon: {
      marginRight: theme.spacing.unit,
    },
  });

const BackButton = translate(
  connect(
    null,
    {
      goBack,
      push,
    },
  )(
    withStyles(styles)(({ classes, translate, goBack, push, goTo }: any) => (
      <Button color="default" variant="outlined" onClick={() => (goTo ? push(goTo) : goBack())}>
        <ArrowBackIcon className={classes.icon} />
        {translate('layout.go_back_button')}
      </Button>
    )),
  ),
);

export default BackButton;
