import React from 'react';
import {
  Create,
  FormDataConsumer,
  ImageField,
  ImageInput,
  REDUX_FORM_NAME,
  SimpleForm,
  TextInput,
  translate,
  maxLength,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { change } from 'redux-form';
import { parse } from 'query-string';
import BackButton from 'components/BackButton';

const getLogoSuggestion = async (name: string | null) => {
  if (!name) {
    return null;
  }

  const suggestions = await fetch(
    `https://autocomplete.clearbit.com/v1/companies/suggest?query=${name}`,
  ).then(response => response.json());

  if (!(suggestions && suggestions[0])) {
    return null;
  }

  const suggestedCompany = suggestions[0];
  const suggestedLogoFile = await fetch(suggestedCompany.logo).then(response => response.blob());

  return {
    rawFile: new File([suggestedLogoFile], suggestedCompany.name),
    src: suggestedCompany.logo,
    title: suggestedCompany.name,
  };
};

const CompanyCreate = ({ translate, ...props }: any) => {
  const { consultantskillset, experienceId } = parse(props.location.search);

  const redirect = (_: any, id: string) => {
    return `/experiences/${
      experienceId ? experienceId : 'create'
    }?consultantskillset=${consultantskillset}&companyId=${id}`;
  };

  const redirectBack = `/experiences/${
    experienceId ? experienceId : 'create'
  }?consultantskillset=${consultantskillset}`;

  return (
    <Create {...props} actions={<BackButton goTo={redirectBack} />}>
      <SimpleForm redirect={redirect}>
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }: any) => (
            <TextInput
              source="name"
              validate={maxLength(255)}
              onChange={async (event: any) => {
                const logoSuggestion = await getLogoSuggestion(event.target.value);
                dispatch(change(REDUX_FORM_NAME, 'logo', logoSuggestion));
              }}
              parse={(name: string) => name.toUpperCase()}
            />
          )}
        </FormDataConsumer>
        <ImageInput
          source="logo"
          accept="image/*"
          placeholder={
            <Typography>{translate('resources.companies.fields.logo_placeholder')}</Typography>
          }
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};

export default translate(CompanyCreate);
