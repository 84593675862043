import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getUrlParameter } from 'services/urls';
import { API_URL } from 'services/dataProvider';
import { renderInput, renderSelectField } from './InputComponents';
import { ORDERED_SUBSIDIARIES, subsidiaryTranslateKeys } from 'services/subsidiaries';

interface FormData {
  first_name: string;
  last_name: string;
  password: string;
  confirm_password: string;
  subsidiary: string;
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: spacing.unit,
    },
  });

const signup = async (values: FormData, dispatch: any, props: any) => {
  const response = await fetch(`${API_URL}/auth/users/`, {
    method: 'POST',
    headers: new Headers({
      'content-type': 'application/json',
    }),
    body: JSON.stringify({
      // FIXME
      // This is a security issue!
      // As of now, the user could set a different email than the one given in the invite
      // However, django-invitations do not provide an easy way to reuse the token in the signup form
      // without using django-allauth which would require to redesign the auth part or invitation part
      // of the app. So leaving this here by lack of time, sorry :/
      email: getUrlParameter('email'),
      password: values.password,
      first_name: values.first_name,
      last_name: values.last_name,
      subsidiary: values.subsidiary,
    }),
  });

  if (response.status === 400) {
    const content = await response.json();
    throw new SubmissionError(content);
  } else {
    props.push('/login?signup=1');
  }
};

const SignupForm = ({ classes, handleSubmit, submitting, translate }: any) => (
  <form onSubmit={handleSubmit}>
    <div className={classes.form}>
      <div className={classes.input}>
        <Field
          autoFocus
          id="first_name"
          name="first_name"
          component={renderInput}
          label={translate('signup.first_name')}
          disabled={submitting}
        />
      </div>
      <div className={classes.input}>
        <Field
          id="last_name"
          name="last_name"
          component={renderInput}
          label={translate('signup.last_name')}
          disabled={submitting}
        />
      </div>
      <div className={classes.input}>
        <Field
          id="password"
          name="password"
          component={renderInput}
          label={translate('signup.password')}
          type="password"
          disabled={submitting}
        />
      </div>
      <div className={classes.input}>
        <Field
          id="confirm_password"
          name="confirm_password"
          component={renderInput}
          label={translate('signup.confirm_password')}
          type="password"
          disabled={submitting}
        />
      </div>
      <div className={classes.input}>
        <Field
          id="subsidiary"
          name="subsidiary"
          component={renderSelectField}
          label={translate('signup.subsidiary')}
          type="select"
          disabled={submitting}
        >
          {ORDERED_SUBSIDIARIES.map(subsidiaryName => (
            <MenuItem value={subsidiaryName}>
              {translate(subsidiaryTranslateKeys[subsidiaryName])}
            </MenuItem>
          ))}
        </Field>
      </div>
    </div>
    <CardActions>
      <Button
        variant="raised"
        type="submit"
        color="primary"
        disabled={submitting}
        className={classes.button}
      >
        {submitting && <CircularProgress className={classes.icon} size={18} thickness={2} />}
        {translate('signup.submit')}
      </Button>
    </CardActions>
  </form>
);

const enhance = compose(
  withStyles(styles),
  translate,
  connect(
    null,
    { push },
  ),
  reduxForm({
    form: 'signUp',
    validate: (values: FormData, props: any) => {
      const errors = {
        first_name: '',
        last_name: '',
        password: '',
        confirm_password: '',
        subsidiary: '',
      };
      const { translate } = props;

      if (!values.first_name) {
        errors.first_name = translate('ra.validation.required');
      }
      if (!values.last_name) {
        errors.last_name = translate('ra.validation.required');
      }
      if (!values.password) {
        errors.password = translate('ra.validation.required');
      }
      if (!values.confirm_password) {
        errors.confirm_password = translate('ra.validation.required');
      }
      if (!values.subsidiary) {
        errors.subsidiary = translate('ra.validation.required');
      }
      if (
        values.password &&
        values.confirm_password &&
        values.password !== values.confirm_password
      ) {
        errors.confirm_password = translate('signup.confirm_password_error');
      }

      return errors;
    },
    onSubmit: signup,
  }),
);

const EnhancedSignupForm = enhance(SignupForm);

export default EnhancedSignupForm;
