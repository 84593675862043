import { resolveBrowserLocale } from 'react-admin';
import { format } from 'date-fns';
import { de, enGB, fr, it } from 'date-fns/locale';

const locales: any = {
  de,
  en: enGB,
  fr,
  it,
};

export const saveFormat = 'yyyy-MM-dd';

export const formatDate = (date: Date, parseFormat: string) => {
  const browserLocale = resolveBrowserLocale();
  let usedLocale = locales.en;

  if (browserLocale in locales) {
    usedLocale = locales[browserLocale];
  }

  return format(date, parseFormat, { locale: usedLocale });
};

// API to input
export const apiDateFormatter = (stringDate: Date) => stringDate && new Date(stringDate);

// input to API
export const apiDateParser = (date: Date) => date && format(date, saveFormat);
