import { colorPalette } from 'stylesheet';
import { createStyles, Theme } from '@material-ui/core';

export const styles = ({ palette }: Theme) =>
  createStyles({
    tableContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      marginTop: '3mm',
    },
    table: {
      width: '100%',
      '& td': {
        verticalAlign: 'bottom',
      },
    },
    firstEmptyCol: {
      width: '2mm',
    },
    emptyCol: {
      width: '2mm',
    },
    lastEmptyCol: {
      width: '5mm',
    },
    headerUnderline: {
      borderBottom: `solid ${palette.primary.main} 1mm`,
      paddingBottom: '2mm',
      fontSize: '11pt',
    },
    secondHeader: {
      '& $headerUnderline': {
        paddingTop: '6mm',
      },
    },
    dataRow: {
      '& td': {
        padding: '4mm 0 2mm 0',
        whiteSpace: 'nowrap',
      },
    },
    dataUnderline: {
      borderBottom: `solid ${colorPalette.lightText} 0.5mm`,
    },
    bold: {
      fontWeight: 'bold',
      color: palette.primary.dark,
    },
    toCenter: {
      textAlign: 'center',
    },
    checkImageContainer: {
      position: 'relative',
    },
    checkImage: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    notEmptyCol: {
      width: '20%',
    },
  });
