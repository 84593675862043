import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { translate } from 'react-admin';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import compose from 'recompose/compose';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { API_URL } from 'services/dataProvider';
import { renderInput } from './InputComponents';

interface FormData {
  new_password: string;
  re_new_password: string;
  uid: string;
  token: string;
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: spacing.unit,
    },
  });

const resetPassword = async (values: FormData, dispatch: any, props: any) => {
  const response = await fetch(`${API_URL}/auth/users/reset_password_confirm/`, {
    method: 'POST',
    headers: new Headers({
      'content-type': 'application/json',
    }),
    body: JSON.stringify({
      new_password: values.new_password,
      re_new_password: values.re_new_password,
      uid: props.params.uid,
      token: props.params.token,
    }),
  });

  if (response.status === 400) {
    const content = await response.json();
    //hack to display error message on token and uid
    content.new_password = content.new_password ? content.new_password : [];
    content.uid = content.uid ? content.uid : [];
    content.token = content.token ? content.token : [];
    content.new_password = [...content.new_password, ...content.uid, ...content.token];
    throw new SubmissionError(content);
  } else {
    props.push('/login');
  }
};

const ResetPasswordForm = ({ classes, handleSubmit, submitting, translate, params }: any) => (
  <>
    <form onSubmit={handleSubmit}>
      <div className={classes.form}>
        <div className={classes.input}>
          <Field
            id="new_password"
            name="new_password"
            component={renderInput}
            label={translate('signup.password')}
            type="password"
            disabled={submitting}
          />
        </div>
        <div className={classes.input}>
          <Field
            id="re_new_password"
            name="re_new_password"
            component={renderInput}
            label={translate('signup.confirm_password')}
            type="password"
            disabled={submitting}
          />
        </div>
      </div>
      <CardActions>
        <Button
          variant="raised"
          type="submit"
          color="primary"
          disabled={submitting}
          className={classes.button}
        >
          {submitting && <CircularProgress className={classes.icon} size={18} thickness={2} />}
          {translate('resetPassword.submit')}
        </Button>
      </CardActions>
    </form>
  </>
);

const enhance = compose(
  withStyles(styles),
  connect(
    null,
    { push },
  ),
  reduxForm({
    form: 'resetPassword',
    validate: (values: FormData, props: any) => {
      const errors = {
        new_password: '',
        re_new_password: '',
      };
      const { translate } = props;

      if (!values.new_password) {
        errors.new_password = translate('ra.validation.required');
      }
      if (!values.re_new_password) {
        errors.re_new_password = translate('ra.validation.required');
      }
      if (
        values.new_password &&
        values.re_new_password &&
        values.new_password !== values.re_new_password
      ) {
        errors.re_new_password = translate('signup.confirm_password_error');
      }

      return errors;
    },
    onSubmit: resetPassword,
  }),
);

const EnhancedResetPasswordForm = translate(enhance(ResetPasswordForm));

export default EnhancedResetPasswordForm;
